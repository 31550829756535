/** Revanista Web Application entry point */

import { createRoot } from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.scss";
import { appStore } from "./App/store";
import { Provider as MobxProvider } from "mobx-react";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";
import React from "react";

const ROOT = (
  <ReduxProvider store={store}>
    <MobxProvider appStore={appStore}>
      <App store={appStore} />
    </MobxProvider>
  </ReduxProvider>
);
const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(ROOT);
}
// ROOT.render(ROOT); // This line is not needed and causes an error

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
