// TODO: Keep only necessary icons
import {
  ArrowDownward,
  Search,
  Edit,
  ViewColumn,
  SaveAlt,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  Check,
  FilterList,
  Remove,
  AddBox,
  Clear,
  DeleteOutline,
} from "@mui/icons-material";

import React, { forwardRef } from "react";
import MaterialTable, { Icons } from "material-table";

const criteriaLookup: any = {};
criteriaLookup["leftToSell"] = "left to sell";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export interface HistoryData {
  timestamp: number;
  user: string;
  event: string;
  value: string;
  target: {
    rt: string;
    rp?: string;
  };
}

interface State {
  data: any;
  hotel: any;
  maxRateBandId: number;
}

export default class CriteriaMatrix extends React.Component {
  state: State = {
    data: {},
    hotel: {},
    maxRateBandId: 0,
  };

  constructor(props: any) {
    super(props);
    this.state.hotel = props.hotel;
    this.exportCriterias = this.exportCriterias.bind(this);
    this.state.data = this.formatData(props.hotel);
    let maxRateBandId = 0;
    for (let row of this.state.data) {
      if (row.rateBand > maxRateBandId) {
        maxRateBandId = row.rateBand;
      }
    }
    this.state.maxRateBandId = maxRateBandId;
  }

  exportCriterias() {
    let res: any[] = [];
    for (let item of this.state.data) {
      res[item.rateBand - 1] = {};
      res[item.rateBand - 1][item.criteria] = {
        min: item.min,
        max: item.max,
      };
    }
    return res;
  }

  formatData(hotel: any): any[] {
    let res: any[] = [];
    // @ts-ignore
    hotel.suggestionParams.matrixes[0].bands.forEach(
      (band: any, index: number, _: []) => {
        Object.keys(band.criterias).forEach((name) => {
          res.push({
            rateBand: index + 1,
            criteria: name,
            min: band.criterias[name].min,
            max: band.criterias[name].max,
          });
        });
      },
    );
    return res;
  }

  render() {
    // @ts-ignore
    let data = JSON.parse(JSON.stringify(this.state.data));
    return (
      <React.Fragment>
        <br />
        <div>
          <MaterialTable
            columns={[
              {
                title: "Rate band",
                field: "rateBand",
                editable: "never",
              },
              { title: "criteria", field: "criteria", lookup: criteriaLookup },
              {
                title: "min",
                field: "min",
                type: "numeric",
                validate: (rowData: any) => {
                  if (rowData.min < 0) {
                    return "Negative number is not allowed";
                  } else {
                    return "";
                  }
                },
              },
              {
                title: "max",
                field: "max",
                type: "numeric",
                validate: (rowData: any) => {
                  if (rowData.max < 0) {
                    return "Negative number is not allowed";
                  } else if (rowData.max <= rowData.min) {
                    return "Max can't be less than or equal to  Min.";
                  } else {
                    return "";
                  }
                },
              },
            ]}
            data={data}
            options={{
              search: false,
              //pageSize:8,
              exportButton: true,
              headerStyle: {
                padding: 5,
                fontWeight: 700,
                fontSize: "0.9em",
                alignContent: "center",
              },
              //maxBodyHeight:'40px',
              rowStyle: {
                padding: 5,
                fontSize: "3 em",
              },
            }}
            editable={{
              isDeletable: (rowData: any) => {
                return true;
              },
              onRowAdd: (newData) =>
                new Promise<void>((resolve, reject) => {
                  setTimeout(() => {
                    {
                      const data = this.state.data;
                      newData = {
                        rateBand: this.state.maxRateBandId + 1,
                        ...newData,
                      };
                      newData.tableData = { id: this.state.maxRateBandId };
                      data.push(newData);
                      this.setState({ data });
                    }
                    resolve();
                  }, 1000);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise<void>((resolve, reject) => {
                  setTimeout(() => {
                    {
                      const data = this.state.data;
                      let index = 0;
                      data.forEach((item: any, i: number) => {
                        if (item.rateBand == oldData.rateBand) {
                          index = i;
                        }
                      });
                      data[index] = newData;
                      this.setState({ data });
                    }
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise<void>((resolve, reject) => {
                  setTimeout(() => {
                    {
                      let data = this.state.data;
                      const index = data.indexOf(oldData);
                      data.splice(index, 1);

                      this.setState({
                        data,
                        maxRateBandId: this.state.maxRateBandId - 1,
                      });
                    }
                    resolve();
                  }, 1000);
                }),
            }}
            title=""
            icons={tableIcons}
            localization={{
              body: {
                editRow: { deleteText: "This can't be undone. Are you sure?" },
              },
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
