import { API } from "aws-amplify";

export class OtaApi {
  /**
   * Retrieves the complist of a given hotel based on its OTA ID
   * @param {number} hotelId - otaHotelId of the hotel we are displaying data about
   * @returns {Promise} Promise which will return the answer of the API call
   */
  static async getOtaHotelCompList(otaHotelId: number): Promise<any> {
    return await API.get("yield", `/ota/${otaHotelId}/competitors`, {});
  }

  /**
   * Retrieves the name,id,subscription_id of the OTA Hotels from OTA table
   * @returns {Promise} Promise which will return the answer of the API call
   */
  static async getOtaHotelCompleteList(): Promise<any> {
    return await API.get("yield", `/ota/hotels`, {});
  }

  /**
   * Retrieves today's updated date for an OTA hotel ID
   * @param {number} hotelOtaId - otaHotelId of the hotel we are retrieving data about
   * @returns {Promise} Promise which will return the answer of the API call
   */
  static async getOtaHotelUpdatedDate(hotelOtaId: number): Promise<any> {
    return await API.get("yield", `/ota/${hotelOtaId}/updateddate`, {});
  }

  static async getRatesFromOTATable(
    otaHotelId: number,
    startDate: string,
    endDate: string,
  ): Promise<any> {
    return await API.get(
      "yield",
      `/ota/${otaHotelId}/rates/${startDate}/${endDate}`,
      {},
    );
  }

  static async getDemandFromOTATable(
    otaHotelId: number,
    startDate: string,
    endDate: string,
  ): Promise<any> {
    return await API.get(
      "yield",
      `/ota/${otaHotelId}/demand/${startDate}/${endDate}`,
      {},
    );
  }

  static async compareOTADataFromPickupData(
    otaHotelId: number,
    pickupDate: string,
    startDate: string,
    endDate: string,
  ): Promise<any> {
    return await API.get(
      "yield",
      `/ota/${otaHotelId}/pickup/${pickupDate}/${startDate}/${endDate}`,
      {},
    );
  }
}
