import React, { Component, forwardRef, Fragment } from "react";
import { toast } from "react-toastify";
import MaterialTable, { Icons } from "material-table";
import { IFormulaOveridesConfigProps, FormulaOverideState } from "./defs";
import {
  ArrowDownward,
  Search,
  ViewColumn,
  SaveAlt,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  Check,
  FilterList,
  Remove,
  AddBox,
  Clear,
  DeleteOutline,
} from "@mui/icons-material";
import withRouter from "../../withRouter";
import { validateRTFormula } from "App/utils/formulaValidator";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} id="add-new-ows" ref={ref} />
  )),
  Check: forwardRef((props, ref) => (
    <Check {...props} id="check-ows" ref={ref} />
  )),
  Clear: forwardRef((props, ref) => (
    <Clear {...props} id="clear-ows" ref={ref} />
  )),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} id="delete-ows" ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class FormulaOverides extends Component<IFormulaOveridesConfigProps> {
  state: FormulaOverideState = {
    formulas: [],
    creationMode: false,
    newLabel: "",
    newFormula: "",
    isEdited: false,
    defaultLabel: "",
  };
  constructor(props: IFormulaOveridesConfigProps) {
    super(props);
    this.state.formulas = JSON.parse(
      JSON.stringify(props.hotel.cm.channelConfig.ows.SeasonnalConfig.formulas),
    );
    this.state.defaultLabel =
      props.hotel.cm.channelConfig.ows.label || "Default";
  }

  componentDidUpdate(prevProps: IFormulaOveridesConfigProps) {
    if (prevProps.isEdited !== this.props.isEdited) {
      if (this.props.isEdited === true) {
        this.setState({ isEdited: false });
      }
    }
  }

  render() {
    const data = JSON.parse(JSON.stringify(this.state.formulas));
    if (data.length > 0) {
      data.forEach((item: { formula: string; order?: number }) => {
        item.order = Number(item.formula.replace(/BR/g, "").replace(/\s/g, ""));
      });
      data.sort(
        (
          a: { formula: string; order: number },
          b: { formula: string; order: number },
        ) => parseFloat(b.order.toString()) - parseFloat(a.order.toString()),
      );
    }
    return (
      <Fragment>
        {this.props.isAdmin.admin[0] === "*" ? (
          <MaterialTable
            columns={[
              {
                title: "Label",
                field: "label",
                type: "string",
                validate: (rowData: any) => {
                  if (
                    rowData.tableData &&
                    rowData.tableData.editing === "delete"
                  ) {
                    return "";
                  }
                  if (
                    typeof rowData.label === "undefined" ||
                    rowData.label === ""
                  ) {
                    return "Mandatory field";
                  }
                  const formulas = [...this.state.formulas];
                  const isDuplicate = formulas.some(
                    (item) =>
                      item.label.toLowerCase() === rowData.label.toLowerCase(),
                  );
                  if (isDuplicate) {
                    return "Duplicate label not allowed";
                  }
                  return "";
                },
              },
              {
                title: "Formula",
                field: "formula",
                type: "string",
                validate: (rowData: any) => {
                  if (
                    rowData.tableData &&
                    rowData.tableData.editing === "delete"
                  ) {
                    return "";
                  }
                  if (
                    typeof rowData.formula === "undefined" ||
                    rowData.formula === ""
                  ) {
                    return "Mandatory field";
                  }
                  if (!validateRTFormula(rowData.formula)) {
                    return "Invalid formula";
                  }
                  return "";
                },
              },
            ]}
            data={data}
            options={{
              search: false,
              sorting: false,
            }}
            style={{ margin: "20" }}
            title={"OWS Formula Overrides"}
            editable={{
              isDeletable: (_rowData) => true,
              onRowAdd: (newData: any) =>
                new Promise<void>((resolve, _reject) => {
                  setTimeout(() => {
                    if (this.props.isAdmin.admin[0] === "*") {
                      let formulas = [...this.state.formulas];
                      formulas.push(newData);
                      this.setState({ formulas });

                      // Create new hotel object with updated formulas
                      const updatedHotel = {
                        ...this.props.hotel,
                        cm: {
                          ...this.props.hotel.cm,
                          channelConfig: {
                            ...this.props.hotel.cm.channelConfig,
                            ows: {
                              ...this.props.hotel.cm.channelConfig.ows,
                              SeasonnalConfig: {
                                ...this.props.hotel.cm.channelConfig.ows
                                  .SeasonnalConfig,
                                formulas: [...formulas],
                              },
                            },
                          },
                        },
                      };

                      this.props.onUpdate(updatedHotel);
                    }
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData: { label: string; formula: string }) =>
                new Promise<void>((resolve) => {
                  setTimeout(() => {
                    if (this.props.isAdmin.admin[0] === "*") {
                      const formulas = [...this.state.formulas];
                      if (oldData) {
                        this.setState({ isEdited: true });
                      }
                      let index = 0;
                      formulas.forEach(
                        (
                          item: { label: string; formula: string },
                          i: number,
                        ) => {
                          if (item.label === oldData.label) {
                            index = i;
                          }
                        },
                      );
                      formulas.splice(index, 1);
                      this.setState({ formulas });

                      // Create new hotel object with updated formulas
                      const updatedHotel = {
                        ...this.props.hotel,
                        cm: {
                          ...this.props.hotel.cm,
                          channelConfig: {
                            ...this.props.hotel.cm.channelConfig,
                            ows: {
                              ...this.props.hotel.cm.channelConfig.ows,
                              SeasonnalConfig: {
                                ...this.props.hotel.cm.channelConfig.ows
                                  .SeasonnalConfig,
                                formulas: [...formulas],
                              },
                            },
                          },
                        },
                      };

                      this.props.onUpdate(updatedHotel);
                    }
                    resolve();
                  }, 1000);
                }),
            }}
            localization={{
              body: {
                editRow: { deleteText: "This can't be undone. Are you sure?" },
              },
            }}
            icons={tableIcons}
          />
        ) : (
          <MaterialTable
            columns={[
              { title: "Label", field: "label", type: "string" },
              {
                title: "Formula",
                field: "formula",
                type: "string",
                validate: (rowData: any) => {
                  if (!validateRTFormula(rowData.formula)) {
                    return "Invalid formula";
                  } else {
                    return "";
                  }
                },
              },
            ]}
            data={data}
            options={{
              search: false,
              sorting: false,
            }}
            style={{ margin: "20" }}
            title={"OWS Formula Overrides"}
            localization={{
              body: {
                editRow: { deleteText: "This can't be undone. Are you sure?" },
              },
            }}
            icons={tableIcons}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(FormulaOverides);
