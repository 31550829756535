import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { Component } from "react";
import SectionTitle from "../../elements/sectionTitle";
import "./dropdown.css";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Tooltip,
  TextField,
  Grid,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ThemeProvider,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { baseTheme } from "../../../mui-theme";
import { toast } from "react-toastify";
import { ConfigService, YieldSheetService } from "../../services";
import moment from "moment";
import CompSetsTable from "./CompSetsTable";
import { OtaApi } from "../../../api/ota";
import withRouter from "../../withRouter";
import { YieldApi } from "../../../api/yield";
import { connect } from "react-redux";
import { setActiveHotel } from "redux/Slice/hotelSlice";

export interface ICompSetProps {
  router: any;
  activeHotel: any;
  setActiveHotel: (hotel: any) => void;
}

export interface ICompSetState {
  displayUpdateRateWarningOnsave: boolean;
  hotelIds: number;
  hotelDisplayName: string;
  otaCompSetLists: [];
  defaultCompSetSelected: string;
  hotelCompSetParseInt: number[];
  selectedCompSet: number[];
  defaultCompSet: { label: string; value: string }[];
  promiseResolvedCompSet: any;
  finalCompSetList: any;
  isLoading: boolean;
  compSetsTableRef: any;
  OriginalCompSetList: any;
  childKey: number;
  compsetHotelNameList: any;
  newHotelNameValue: any;
  defaultOtaValue: any;
  selectedOtaHotelId: number;
  autoCompleteReset: number;
  isUpdated: boolean;
  hover: boolean;
  otaDropDownFlag: boolean;
  updatedSubscriptionId: number;
  prevState: any;
}

class NewCompSetConfig extends Component<ICompSetProps, ICompSetState> {
  //private compSetsTableRef = React.createRef<any>();

  otaCompSetList: any;

  constructor(props: any) {
    super(props);

    // Initialize with default state
    this.state = {
      defaultOtaValue: "",
      hotelCompSetParseInt: [],
      otaDropDownFlag: false,
      updatedSubscriptionId: 0,
      isLoading: true,
      displayUpdateRateWarningOnsave: false,
      hotelIds: 0,
      hotelDisplayName: "",
      otaCompSetLists: [],
      defaultCompSetSelected: "",
      selectedCompSet: [],
      defaultCompSet: [
        { label: "RO", value: "RO" },
        { label: "BB", value: "BB" },
        { label: "HB", value: "HB" },
        { label: "FB", value: "FB" },
      ],
      promiseResolvedCompSet: [],
      finalCompSetList: [],
      compSetsTableRef: React.createRef(),
      OriginalCompSetList: [],
      childKey: 1,
      compsetHotelNameList: [],
      newHotelNameValue: {},
      selectedOtaHotelId: 0,
      autoCompleteReset: 1,
      isUpdated: false,
      hover: false,
      prevState: {},
    };

    this.otaCompSetList = [];
  }

  async componentDidMount() {
    try {
      // Wait for hotel data to be available
      if (
        !this.props.activeHotel ||
        Object.keys(this.props.activeHotel).length === 0
      ) {
        const pathname = window.location.pathname;
        const parts = pathname.split("/");
        const hotelId = parseInt(parts[1]);

        if (!isNaN(hotelId)) {
          try {
            const hotelData = await YieldApi.getHotelConfig(hotelId);
            this.props.setActiveHotel(hotelData);
          } catch (error) {
            console.error("Error loading hotel data:", error);
            toast.error("Failed to load hotel data");
            return;
          }
        }
      }

      // Update state with hotel data
      this.setState({
        updatedSubscriptionId: this.props.activeHotel?.ota?.subscriptionId || 0,
        selectedOtaHotelId: this.props.activeHotel?.ota?.hotelId,
        defaultCompSetSelected:
          this.props.activeHotel?.ota?.defaultCompsetType || "",
      });

      await this.fetchCompset(false);
    } catch (error) {
      console.error("Error initializing component:", error);
      toast.error("Failed to initialize component");
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps: ICompSetProps) {
    // Check if activeHotel has changed and it's not the initial load
    if (
      prevProps.activeHotel !== this.props.activeHotel &&
      this.props.activeHotel
    ) {
      this.setState({ isLoading: true }, async () => {
        try {
          const subscriptionId =
            this.props.activeHotel?.ota?.subscriptionId || 0;
          const defaultCompsetType =
            this.props.activeHotel?.ota?.defaultCompsetType || "";
          const hotelCompSet = this.props.activeHotel?.ota?.compSet || [];

          // Update state with new hotel data
          this.setState({
            updatedSubscriptionId: subscriptionId,
            defaultCompSetSelected: defaultCompsetType,
            selectedCompSet: hotelCompSet,
            selectedOtaHotelId: this.props.activeHotel?.ota?.hotelId,
          });

          // Fetch and update compset data
          await this.fetchCompset(false);
        } catch (error) {
          console.error("Error updating component:", error);
          toast.error("Failed to update component data");
        } finally {
          this.setState({ isLoading: false });
        }
      });
    }
  }

  async fetchCompset(changeCompset: boolean) {
    try {
      if (!this.props.activeHotel) {
        console.error("Hotel data not available");
        return;
      }

      // Get the correct hotelId based on the context
      const hotelId = changeCompset
        ? this.state.selectedOtaHotelId
        : this.props.activeHotel?.ota?.hotelId;

      if (!hotelId) {
        console.error("Hotel ID not available");
        return;
      }

      // Get the compset list for the selected hotel
      const response =
        await YieldSheetService.revanista.hotel.getCompSetList(hotelId);
      console.log("[FetchCompset] CompSet response:", response);

      const compsetHotelNameList = await OtaApi.getOtaHotelCompleteList();
      console.log("[FetchCompset] Hotel name list:", compsetHotelNameList);

      // Find the matching hotel details
      const matchedOtaHotelDetails = compsetHotelNameList.find(
        (o: { id: number }) => Number(o.id) === Number(hotelId),
      );
      console.log(
        "[FetchCompset] Matched hotel details:",
        matchedOtaHotelDetails,
      );

      if (response) {
        const hotelCompSetParseInt = response.map((item: any) => ({
          ...item,
          id: parseInt(item.id),
        }));

        this.setState({
          hotelCompSetParseInt,
          compsetHotelNameList,
          defaultOtaValue: matchedOtaHotelDetails || "",
        });

        this.otaCompSetList = response;
      }

      // Get the correct compSet based on whether we're changing hotels or not
      let hotelCompSet: number[] = [];
      if (!changeCompset && this.props.activeHotel.ota.compSet) {
        hotelCompSet = this.props.activeHotel.ota.compSet.map((val: string) =>
          parseInt(val),
        );
      } else if (changeCompset && Array.isArray(this.state.selectedCompSet)) {
        hotelCompSet = this.state.selectedCompSet.map((id: any) =>
          parseInt(id),
        );
      }

      // Update the states with the new data
      this.setState({
        hotelCompSetParseInt: hotelCompSet,
        selectedCompSet: hotelCompSet,
      });

      // Update the otaCompSet list with Active states
      const otaCompSet = this.otaCompSetList.map((hotel: any, i: number) => ({
        ...hotel,
        id: parseInt(hotel.id),
        serialNo: i + 1,
        Active: hotelCompSet.includes(parseInt(hotel.id)),
      }));

      // Set the finalCompSetList
      this.setState({ finalCompSetList: otaCompSet });
      console.log("final", otaCompSet);

      // Fetch and update dates for each hotel
      for (let i = 0; i < otaCompSet.length; i++) {
        const updatedDate = await OtaApi.getOtaHotelUpdatedDate(
          otaCompSet[i].id,
        );
        this.setState((prevState) => ({
          finalCompSetList: prevState.finalCompSetList.map(
            (item: any, index: number) => {
              if (index === i) {
                return { ...item, updatedDate: updatedDate };
              }
              return item;
            },
          ),
        }));
      }

      this.setState({ isLoading: false });
    } catch (error) {
      console.error("Error fetching compset:", error);
      toast.error("Failed to fetch compset data");
      this.setState({ isLoading: false });
    }
  }

  //Logic to get default compset from compset dropdown
  handleDefaultCompSet = (event: any) => {
    const selectedValue = event.target.value || event.target.selected;
    this.setState(() => ({
      prevState: {
        ...this.props.activeHotel,
        ota: {
          ...this.props.activeHotel.ota,
          defaultCompsetType: selectedValue,
          compSet: this.state.selectedCompSet,
        },
      },
    }));
    this.setState({
      defaultCompSetSelected: selectedValue,
    });
  };

  //callback function to get child class state into parent class
  handleCallback = (childData: any) => {
    let otaCompSet = [...this.otaCompSetList]; //otatable.json

    // Update Active status for each hotel in otaCompSet
    otaCompSet = otaCompSet.map((hotel, i) => ({
      ...hotel,
      id: parseInt(hotel.id),
      serialNo: i + 1,
      Active: childData.includes(parseInt(hotel.id)),
    }));

    // Update both states at once to ensure consistency
    this.setState({
      selectedCompSet: childData,
      finalCompSetList: otaCompSet,
    });
  };

  //save the compset config
  saveConfig = async () => {
    try {
      if (this.state.selectedCompSet?.length === 0) {
        toast.error("Please select at least one hotel");
        return;
      }

      // Save current selections before API calls
      const currentOtaHotelId =
        this.state.selectedOtaHotelId || this.props.activeHotel.ota.hotelId;
      const currentCompSet = this.state.selectedCompSet;

      const newHotel = JSON.parse(
        JSON.stringify({
          ...this.props.activeHotel,
          ota: {
            ...this.props.activeHotel.ota,
            defaultCompsetType: this.state.defaultCompSetSelected,
            hotelId: currentOtaHotelId,
            compSet: currentCompSet,
          },
        }),
      );
      console.log("new hotel", newHotel);
      // Wait for all API calls to complete

      await toast.promise(
        async () => {
          await YieldApi.updateHotelConfig({
            hotel: newHotel,
            start: moment().format("YYYY-MM-DD"),
          });
          await ConfigService.revanista.hotel.updateConfig(newHotel.hotelId);

          const [updatedHotelConfig, compsetHotelNameList] = await Promise.all([
            YieldApi.getHotelConfig(newHotel.hotelId),
            OtaApi.getOtaHotelCompleteList(),
          ]);
          this.props.setActiveHotel(updatedHotelConfig);
          const matchedOtaHotelDetails = compsetHotelNameList.find(
            (o: { id: number }) => Number(o.id) === Number(currentOtaHotelId),
          );
          this.setState({
            selectedOtaHotelId: currentOtaHotelId,
            selectedCompSet: currentCompSet,
            defaultOtaValue: matchedOtaHotelDetails || "",
            compsetHotelNameList,
            childKey: this.state.childKey + 1,
            otaDropDownFlag: false,
          });

          // Fetch fresh compset data
          await this.fetchCompset(true);
        },
        {
          pending: "Saving competitive set config changes...",
          success: "Competitive set changes saved successfully",
          error: "Failed to save configuration",
        },
      );
    } catch (error) {
      console.error("Error saving config:", error);
      toast.error("Failed to save configuration");
      this.setState({ isLoading: false });
    }
  };

  cancelChanges = async () => {
    this.setState({ otaDropDownFlag: false });
    const defaultCompsetType = this.props.activeHotel.ota.defaultCompsetType;
    const otaHotelId = this.props.activeHotel.ota.hotelId;

    this.setState({
      defaultCompSetSelected: defaultCompsetType,
      isLoading: true,
    });
    this.setState(
      { selectedOtaHotelId: this.props.activeHotel.ota.hotelId },
      async () => {
        await this.fetchCompset(false);
        const matchedOtaHotalDetails = this.state.compsetHotelNameList.find(
          (o: { id: number }) => Number(o.id) === Number(otaHotelId),
        );

        this.setState({
          promiseResolvedCompSet: this.otaCompSetList,
          defaultOtaValue: matchedOtaHotalDetails,
        });
        let hotelCompSet = this.props.activeHotel.ota.compSet;
        hotelCompSet = hotelCompSet.map(function (val: string) {
          return parseInt(val);
        });
        this.setState({ hotelCompSetParseInt: hotelCompSet });

        const otaCompSet = this.state.promiseResolvedCompSet; //otatable.json
        for (let i = 0; i < otaCompSet.length; i++) {
          const otaTest = hotelCompSet.includes(otaCompSet[i]["id"]);
          if (otaTest === true) {
            otaCompSet[i]["Active"] = true;
          } else {
            otaCompSet[i]["Active"] = false;
          }
          otaCompSet[i]["id"] = parseInt(otaCompSet[i]["id"]);
          otaCompSet[i]["serialNo"] = i + 1;
        }
        this.setState({
          OriginalCompSetList: otaCompSet,
          isLoading: false,
          childKey: this.state.childKey + 1,
        });
      },
    );
  };

  refreshOta = async () => {
    this.setState({ isLoading: true });
    const req = {
      hotelId: this.props.activeHotel.hotelId,
      start: moment().format("YYYY-MM-DD"),
      end: moment().add("1", "year").format("YYYY-MM-DD"),
    };
    YieldApi.ingestOta(req)
      .then(async () => {
        toast.warn("OTA is refreshing...");
        await this.fetchCompset(true);
      })

      .catch((err: Error) => {
        console.log(err);
        toast.error("Error while refreshing OTA");
      });
  };

  handleOnMouseOver = () => {
    this.setState({ hover: true });
  };

  handleOnMouseOut = () => {
    this.setState({ hover: false });
  };

  handleChangeOnDropdown = async (selectedOtaHotelId: any) => {
    if (selectedOtaHotelId) {
      this.setState({ isLoading: true });
      this.setState(
        {
          selectedOtaHotelId: selectedOtaHotelId.id,
          otaDropDownFlag: true,
          updatedSubscriptionId: selectedOtaHotelId.subscription_id,
        },
        async () => {
          if (
            Number(selectedOtaHotelId.id) ===
            Number(this.props.activeHotel.ota.hotelId)
          ) {
            this.setState({ otaDropDownFlag: false });
            this.cancelChanges();
            return;
          }
          await this.fetchCompset(true);
          this.setState({
            childKey: this.state.childKey + 1,
            hotelCompSetParseInt: [],
            selectedCompSet: [],
            isLoading: false,
          });
        },
      );
    }
  };

  render() {
    const { hotelCompSetParseInt } = this.state;

    if (!hotelCompSetParseInt) {
      return <div>No data available</div>;
    }

    return (
      <ThemeProvider theme={baseTheme}>
        <Backdrop open={this.state.isLoading} invisible={true}>
          <CircularProgress color="inherit" id="circular-progress" />
        </Backdrop>
        <div className="content">
          <SectionTitle {...{ value: "Competitive List" }} />
          <br />
          <div style={{ flexGrow: "1", position: "relative" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                  id="select-ota-hotel"
                  options={this.state.compsetHotelNameList}
                  getOptionLabel={(option) => {
                    if (option.hasOwnProperty("name")) {
                      return option.name;
                    }
                    return option;
                  }}
                  key={this.state.autoCompleteReset}
                  value={this.state.defaultOtaValue}
                  onChange={(event, selectedOtaHotelId) => {
                    console.log(
                      "[Autocomplete] Selected value:",
                      selectedOtaHotelId,
                    );
                    this.handleChangeOnDropdown(selectedOtaHotelId);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select OTA Hotel Name"
                      fullWidth
                      style={{
                        width: "100%",
                      }}
                      variant="outlined"
                      name="OTAHotelId"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={5}>
                <Button
                  id="competitive-set-refresh"
                  aria-label="split"
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={this.refreshOta}
                  style={{
                    width: "50px",
                    height: "70%",
                    marginTop: "7px",
                  }}
                >
                  <Refresh />
                </Button>
              </Grid>

              <Grid item xs={12} sm={5} md={3}>
                <Tooltip
                  title="Sets the default rate displayed in the competitive set."
                  arrow
                  placement="left"
                >
                  <FormControl style={{ minWidth: 200 }}>
                    {" "}
                    {/* Add this style */}
                    <InputLabel>Default Competitive Set</InputLabel>
                    <Select
                      value={this.state.defaultCompSetSelected}
                      id="default-competitive-set"
                      label="Default Competitive Set"
                      variant="outlined"
                      name="defaultCompsetType"
                      onChange={this.handleDefaultCompSet}
                    >
                      <MenuItem value="oneGuestRO">1 Guest RO</MenuItem>
                      <MenuItem value="oneGuestBB">1 Guest BB</MenuItem>
                      <MenuItem value="RO">2 Guest RO</MenuItem>
                      <MenuItem value="BB">2 Guest BB</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
          <br />
          {this.state.finalCompSetList.length > 0 &&
            this.state.isLoading === false && (
              <CompSetsTable
                key={this.state.childKey}
                ref={this.state.compSetsTableRef}
                items={this.state.finalCompSetList}
                originalItems={this.state.OriginalCompSetList}
                hotelCompSet={this.state.hotelCompSetParseInt}
                parentCallback={this.handleCallback}
              />
            )}
          <br />
          {this.state.finalCompSetList.length > 0 &&
            this.state.isLoading === false && (
              <div style={{ float: "right", marginBottom: "5vh" }}>
                <Button
                  onClick={() => this.cancelChanges()}
                  color="secondary"
                  variant="contained"
                  style={{
                    backgroundColor: "#808080",
                    color: "#ffffff",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  Cancel Changes
                </Button>
                <Button
                  onClick={this.saveConfig}
                  color="primary"
                  variant="contained"
                  style={{
                    backgroundColor: "#ce0e29",
                    color: "#ffffff",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  Save Changes
                </Button>
              </div>
            )}
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  activeHotel: state.hotelData.activeHotel,
});

const mapDispatchToProps = (dispatch: any) => ({
  setActiveHotel: (hotel: any) => dispatch(setActiveHotel(hotel)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(NewCompSetConfig));
