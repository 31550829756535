import React, { Component } from "react";
import withRouter from "../withRouter";
import "./pageNotFound.css";
import { NavLink } from "react-router-dom";
import pageNotFoundLogo from "../../assets/images/X.png";
import { baseTheme } from "../../mui-theme";

import { AccountCircle } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material";

let logo: string;
import(`assets/images/${process.env.REACT_APP_STAGE}/navbar-logo.png`).then(
  (module) => {
    logo = module.default;
  },
);

interface State {
  dropdownActive: boolean;
}

class PageNotFound extends Component<{
  // aciveHotelId: any;
  router: any;
  onLogout: () => void;
  username: string;
  changeIDState: () => void;
}> {
  readonly state: State = {
    dropdownActive: false,
  };

  componentDidMount() {
    window.addEventListener("click", this.closeDropdown);
  }

  handleOnLogout = () => {
    this.props.onLogout();
    setTimeout(() => {
      this.props.router.navigate("/");
    }, 500);
  };

  handleHomeButton = () => {
    this.props.router.navigate("/");
    this.props.changeIDState();
  };

  private closeDropdown = (event: MouseEvent) => {
    if (!(event.target as HTMLElement).classList.contains("dropdown-toggle")) {
      this.setState({ dropdownActive: false });
    }
  };

  private toggleDropdown = () => {
    this.setState({ dropdownActive: !this.state.dropdownActive });
  };

  render() {
    const dropdownClass = this.state.dropdownActive ? "show" : "";

    return (
      <ThemeProvider theme={baseTheme}>
        <div className="notFound">
          <nav
            className="navbar navbar-expand navbar-dark bg-dark mb-4"
            style={{ position: "fixed", width: "100%", zIndex: "1000" }}
          >
            <NavLink to="" end>
              <img
                className="logo"
                src={logo}
                id="navbar-revanista-logo"
                alt=""
                onClick={this.handleHomeButton}
              />
            </NavLink>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="revNavBar">
                <li className={`nav-item dropdown-bar${dropdownClass}`}>
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    onClick={this.toggleDropdown}
                    style={{ color: "white" }}
                  >
                    <AccountCircle color="primary" />
                    &nbsp;{this.props.username}
                  </a>
                  <div
                    className={`dropdown-menu dropdown-menu-right ${dropdownClass}`}
                  >
                    <a
                      className="dropdown-item"
                      role="button"
                      onClick={this.handleOnLogout}
                    >
                      Log out
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          <div className="notFoundMainDiv">
            <div className="row1">
              <img
                src={pageNotFoundLogo}
                className="notFoundImg"
                alt="not found"
              />
              <h1 className="text404">404</h1>
            </div>
            <div>
              <h1 className="sorryText">Sorry, page not found</h1>
            </div>
            <div>
              <button
                className="notFoundButton"
                onClick={this.handleHomeButton}
              >
                Back to home
              </button>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default withRouter(PageNotFound);
