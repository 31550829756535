import { BackendEvent } from "./../models/yield.models";
import {
  IExecSummaryPutrequest,
  IDeleteFileRequest,
  ILeftToSellUpdateList,
  IFetchCalendarEventsRequest,
  IBulkManualRateUpdate,
  IHotelConf,
  IOWSOverrideUpdate,
  BulkOwsOverridePayload,
  IEditSubscriptionlist,
} from "./../models/api.models";
import { API } from "aws-amplify";
import {
  IAvailabilityUpdate,
  IDayUpdate,
  ILeftToSellUpdate,
  ISuggestedBaseRateUpdate,
  IRestrictionsUpdate,
  IManualRateUpdate,
  INotify,
  IRewardsUpdate,
  IRatePlanConf,
  IRoomType,
  IDeleteRateplan,
  IDeleteRoomType,
  ISuggestionParams,
  IFileUploadLinkRequest,
  IFileUpload,
  IFileRequest,
} from "../models/api.models";
import axios from "axios";

export class YieldApi {
  static async notify(body: INotify) {
    return API.post("yield", `/notify`, { body });
  }

  static async list(
    hotelId: number,
    startDate: string,
    endDate: string,
  ): Promise<IDayUpdate[]> {
    return API.get(
      "yield",
      `/days/yield/${hotelId}/${startDate}/${endDate}`,
      {},
    );
  }

  static async publishAvailabilitiesInView(body: IDayUpdate) {
    return API.post("yield", `/availabilities/${body.hotelId}/publish`, {
      body,
    });
  }

  static async getHotelConfig(hotelId: number): Promise<IHotelConf> {
    return API.get("yield", `/hotels/${hotelId}`, {});
  }

  static async getUsersHotelsMeta(): Promise<IHotelConf[]> {
    return API.get("yield", `/hotels`, {});
  }

  static async getLastCalendarDate(hotelId: number) {
    return API.get("yield", `/hotels/${hotelId}/lastcalendardate`, {});
  }

  static async publishRatesInView(body: IDayUpdate) {
    return API.post("yield", `/rates/${body.hotelId}/publish`, { body });
  }

  static async publishRestrictions(body: IDayUpdate) {
    return await API.post("yield", `/restrictions/${body.hotelId}/publish`, {
      body,
    });
  }

  static async getFirstPickupData(hotelId: number, updatedDate: number) {
    return API.get(
      "yield",
      `/lefttosell/${hotelId}/pickup/${updatedDate}/first`,
      {},
    );
  }

  static async getLastPickupData(hotelId: number, updatedDate: number) {
    return API.get(
      "yield",
      `/lefttosell/${hotelId}/pickup/${updatedDate}/last`,
      {},
    );
  }

  static async updateAvailability(availabilities: IAvailabilityUpdate[]) {
    const request = (body: IAvailabilityUpdate) =>
      API.put("yield", `/availabilities/${body.hotelId}`, { body });
    return Promise.all(availabilities.map(request));
  }

  static async fetchCalendarEvents(request: IFetchCalendarEventsRequest) {
    return API.get(
      "yield",
      `/events/${request.hotelId}/${request.start}/${request.end}`,
      {},
    );
  }

  static async updateCalendarEvents(body: BackendEvent) {
    return API.put("yield", `/events/${body.hotelId}`, { body });
  }

  static async updateLeftToSellList(body: ILeftToSellUpdateList) {
    return API.put("yield", `/lefttosell/${body.hotelId}`, { body });
  }

  static async ingestOta(body: IDayUpdate) {
    return API.post("yield", `/ingestOta`, { body });
  }

  static async acceptSuggestedRate(body: ISuggestedBaseRateUpdate) {
    return API.post(
      "yield",
      `/rates/${body.hotelId}/suggested/${body.start}/${body.end}`,
      { body },
    );
  }

  static async removeSuggestedRate(body: ISuggestedBaseRateUpdate) {
    return API.del(
      "yield",
      `/rates/${body.hotelId}/suggested/${body.start}/${body.end}`,
      {},
    );
  }

  static async updateSuggestedBaseRate(updates: ISuggestedBaseRateUpdate[]) {
    const request = (body: ISuggestedBaseRateUpdate) =>
      API.put(
        "yield",
        `/rates/${body.hotelId}/suggested/${body.start}/${body.end}`,
        { body },
      );
    return Promise.all(updates.map(request));
  }

  static async writeManualRate(body: IManualRateUpdate) {
    return await API.post("yield", `/rates/manual`, { body });
  }

  static async writeRestrictions(body: IRestrictionsUpdate) {
    return await API.post("yield", `/restrictions/${body.hotelId}`, { body });
  }

  static async bulkUpdateOwsOverride(body: BulkOwsOverridePayload) {
    return API.put("yield", `/rates/ows/override/async`, { body });
  }

  static async updateOwsOverride(body: BulkOwsOverridePayload) {
    return API.put("yield", `/rates/ows/override`, { body });
  }

  static async bulkUpdateRates(body: IDayUpdate) {
    return API.post("yield", `/rates/async`, {
      body,
    });
  }

  static async updateRates(body: IDayUpdate) {
    return API.post("yield", `/rates/${body.hotelId}`, {
      body,
    });
  }

  static async bulkUpdateManualRates(body: IBulkManualRateUpdate) {
    return API.post("yield", `/rates/manual/async`, { body });
  }

  static async bulkUpdateRewards(body: IRewardsUpdate) {
    return await API.post("yield", `/rewards/async`, { body });
  }

  static async updateRewards(body: IRewardsUpdate) {
    return await API.post("yield", `/rewards`, { body });
  }

  static async bulkUpdateRestrictions(body: IRestrictionsUpdate) {
    return await API.post("yield", `/restrictions/${body.hotelId}/async`, {
      body,
    });
  }

  static async writeRateplan(body: IRatePlanConf) {
    return await API.put(
      "yield",
      `/config/${body.hotelId}/rateplans/${body.id}`,
      { body },
    );
  }

  static async writeRoomType(body: IRoomType) {
    return await API.put(
      "yield",
      `/config/${body.hotelId}/roomtypes/${body.id}`,
      { body },
    );
  }

  static async writeRtSortOrder(body: IRoomType) {
    return await API.put(
      "yield",
      `/config/${body.hotelId}/roomtypes/sortorder`,
      { body },
    );
  }

  static async writeRpSortOrder(body: any) {
    return await API.put(
      "yield",
      `/config/${body.hotelId}/rateplans/sortorder`,
      { body },
    );
  }

  static async deleteRoomType(body: IDeleteRoomType) {
    return await API.del(
      "yield",
      `/config/${body.hotelId}/roomtypes/${body.rtId}`,
      {
        body,
      },
    );
  }

  static async updateHotelConfig(body: IHotelConf) {
    return await API.put("yield", `/config/${body.hotel.hotelId}`, { body });
  }

  static async addNewHotel(body: IHotelConf) {
    return await API.post("yield", `/hotels`, { body });
  }

  static async writeSuggestionParams(body: ISuggestionParams) {
    return await API.put("yield", `/config/${body.hotelId}/matrix`, {
      body,
    });
  }

  static async deleteRateplan(body: IDeleteRateplan) {
    return await API.del(
      "yield",
      `/config/${body.hotelId}/rateplans/${body.id}`,
      {
        body,
      },
    );
  }

  static async getUploadLink(body: IFileUploadLinkRequest) {
    return API.get("yield", `/executive/${body.hotelId}/files/uploadlink`, {});
  }

  static async getDownloadLink(body: IFileRequest) {
    return API.get(
      "yield",
      `/executive/${body.hotelId}/files/${body.filename}/${body.overiddenFileName}`,
      {},
    );
  }

  static async deleteFile(body: IDeleteFileRequest) {
    return API.del(
      "yield",
      `/executive/${body.hotelId}/files/${body.filename}`,
      { body },
    );
  }

  static async uploadFile(body: IFileUpload) {
    const opt = {
      url: body.url || "toto",
      method: "put",
      data: body.data,
      headers: {
        "Content-Type": body.contentType,
        "content-disposition": "attachment",
      },
    };
    return axios.request(opt);
  }

  static async getExecSummaryItems(hotelId: number) {
    return await API.get("yield", `/executive/${hotelId}/items`, {});
  }

  static async putExecSummaryItem(body: IExecSummaryPutrequest) {
    return await API.put("yield", `/executive/${body.hotelId}/items`, {
      body,
    });
  }

  static async editSubscriptionList(body: IEditSubscriptionlist) {
    return await API.post(
      "yield",
      `/users/${body.hotelId}/editSubscriptionList`,
      { body },
    );
  }
}
