import React, { Component } from "react";
import withRouter from "../withRouter";
import { appStore } from "../store";
import { Outlet } from "react-router-dom";
import "./homePage.css";
import { store } from "redux/store";
import { setError } from "redux/Slice/state";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { setActiveHotel } from "redux/Slice/hotelSlice";
import { connect } from "react-redux";
import { YieldApi } from "api/yield";

let logo: string;
import(`assets/images/${process.env.REACT_APP_STAGE}/revanista-logo.png`).then(
  (module) => {
    logo = module.default;
  },
);

interface Props {
  router: any;
}

interface State {
  counter: number;
  searchInput: string;
}

interface StateProps {
  hotels: any[];
}

interface Props extends StateProps {
  router: any;
}

class HomePage extends Component<Props, State> {
  readonly state: State = {
    counter: 0,
    searchInput: "",
  };

  componentDidMount(): void {
    this.setState({ counter: this.state.counter + 1 });
  }

  handleOnClick = async (data: any) => {
    const userHotels = this.props.hotels;
    if (userHotels.length !== 0) {
      try {
        const h = await YieldApi.getHotelConfig(parseInt(data.hotelId));
        store.dispatch(setActiveHotel(h));
        //let rowProps = new Map<number, any>();
        /*rowProps.set(parseInt(target), rowProp);
        appStore.meta.setConfig("rowProps", rowProps);*/
      } catch (err) {
        console.error(err);
        store.dispatch(setError(true));
        throw err;
      }
    }
    console.log(data);

    this.props.router.navigate(`${data.hotelId}`);
  };

  handleChange = (e: any) => {
    const value = e.target.value;
    this.setState({ searchInput: value });
  };

  render() {
    if (this.props.hotels.length === 1) {
      const singleUserHotelId = this.props.hotels[0].hotelId;
      const isSignedIn = localStorage.getItem(
        "amplify-authenticator-authState",
      );
      isSignedIn === "signedIn" &&
        this.props.router.navigate(`/${singleUserHotelId}`);
    }
    const { searchInput } = this.state;

    const searchFilter = this.props.hotels.filter((item: any) => {
      if (searchInput.length === 0) {
        return true;
      } else {
        return item.name.toLowerCase().includes(searchInput.toLowerCase());
      }
    });

    const hotelName = appStore.meta.user.permissions.admin;

    console.log(hotelName);

    return (
      <div className="content">
        <img
          // onClick={this.handleOnHomePage}
          className="homePageLogo"
          src={logo}
          alt=""
        />
        <div className="hotelSearch">
          <TextField
            // style={{width: '50%'}}
            id="outlined-basic"
            label="Search"
            variant="outlined"
            name="searchHotel"
            inputProps={{ maxLength: 20 }}
            onChange={this.handleChange}
          />
        </div>
        <div className="hotelCards">
          {hotelName[0] === "*" && (
            <div
              className="cards"
              onClick={() => this.props.router.navigate(`/onboard-hotel`)}
            >
              <AddIcon className="apartmentIcon" />
              <h5 className="hotelFullName">Onboard Hotel</h5>
            </div>
          )}

          {searchFilter.map((data: any) => {
            return (
              <div className="cards" onClick={() => this.handleOnClick(data)}>
                <ApartmentIcon className="apartmentIcon" />
                <h5 className="hotelFullName">{data.name}</h5>
              </div>
            );
          })}
        </div>
        <Outlet />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  hotels: state.hotelData.hotels,
});

export default connect(mapStateToProps)(withRouter(HomePage));
