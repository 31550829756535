import React, { useEffect, useState } from "react";
import SessionManager from "../utils/sessionManager";
import Popup from "../reusable-components/popup/popup";

const SessionHandler: React.FC = () => {
  const [showWarning, setShowWarning] = useState(false);
  const [showTimeout, setShowTimeout] = useState(false);
  const [remainingTime, setRemainingTime] = useState<number>(0);

  useEffect(() => {
    const sessionManager = SessionManager.getInstance();

    // Set up event listeners
    sessionManager.setEventListener({
      onSessionWarning: (seconds: number) => {
        setRemainingTime(seconds);
        setShowWarning(true);
      },
      onSessionTimeout: () => {
        setShowWarning(false);
        setShowTimeout(true);
      },
      onCountdownTick: (seconds: number) => {
        setRemainingTime(seconds);
      },
    });

    return () => {
      sessionManager.cleanup();
    };
  }, []);

  const handleStayLoggedIn = () => {
    setShowWarning(false);
    // This will reset the timer automatically since it's a user action
  };

  const handleLogout = async () => {
    await SessionManager.getInstance().signOut();
  };

  return (
    <>
      <Popup
        isPopupOpen={showWarning}
        title="Session Expiring Soon"
        content={[
          `Your session will expire in ${Math.floor(remainingTime / 60)}:${(remainingTime % 60).toString().padStart(2, "0")} minutes due to inactivity.`,
          "Would you like to stay logged in?",
        ]}
        action={handleStayLoggedIn}
        actionName="Stay Logged In"
        cancelAction={handleLogout}
        cancelName="Logout"
      />
      <Popup
        isPopupOpen={showTimeout}
        title="Session Expired"
        content={[
          "Your session has expired due to inactivity.",
          "You will be logged out automatically.",
        ]}
        action={handleLogout}
        actionName="Sign Out"
      />
    </>
  );
};

export default SessionHandler;
