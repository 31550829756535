import { createTheme } from "@material-ui/core";

const rtTheme: any = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },
    MuiCard: {
      root: {
        width: "calc(33% - 8px)",
        float: "left",
        marginLeft: "10px",
        marginTop: "10px",
        fontSize: "1em",
      },
    },
    MuiCardContent: {
      root: {
        fontSize: "1em",
      },
    },
    MuiTypography: {
      root: {
        float: "left",
        width: "100%!important",
      },
    },
    MuiTextField: {
      root: {
        display: "inline-block",
        width: 230,
      },
    },
    MuiSvgIcon: {
      root: {
        float: "right",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-bock",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#fce8e6",
          color: "#d93025",
          fontWeight: "bolder",
        },
      },
    },
  },
});

export interface State {
  hotel: any;

  deleteFormula: {
    hotelId: number;
    start: "";
    end: "";
    label: "";
    deleteLabel: "";
  };
  currentSetIndex: number;
  displayUpdateRateWarningOnsave: boolean;
  warningDialogOpened: boolean;
  validateFullName: boolean;
  validateFullNameMsg: string;
  validateFormula: boolean;
  validateFormulaMsg: string;
  validateName: boolean;
  validateNameMsg: string;
  validateContractType: boolean;
  validateContractTypeMsg: string;
  validateTotalRoom: boolean;
  validateTotalRoomMsg: string;
  validateInventory: boolean;
  validateInventoryMsg: string;
  validateCmHotelCode: boolean;
  validateCmHotelCodeMsg: string;
  validatePmsHotelCode: boolean;
  validatePmsHotelCodeMsg: string;
  validateProviderKey: boolean;
  validateProviderKeyMsg: string;
  validatePmsUrlEndpoint: boolean;
  validatePmsUrlEndpointMsg: string;
  validateUserName: boolean;
  validateUserNameMsg: string;
  validateFelexTable: boolean;
  validateFelexTableMsg: string;
  validateOwsFelexTable: boolean;
  validateOwsFelexTableMsg: string;
  validateDefaultBar: boolean;
  validateDefaultBarMsg: string;
  validateMinRate: boolean;
  validateMinRateMsg: string;
  validateMaxRate: boolean;
  validateMaxRateMsg: string;
  validatePickupRange: boolean;
  validatePickupRangeMsg: string;
  validateOtaPickupRange: any;
  validateOtaPickupRangeMsg: string;
  setGuestline: boolean;
  onSave: boolean;
  hover: boolean;
  disableOTAPickup: boolean;
  isSyncOn: boolean;
  openRewardsPopup: boolean;
  rewardsPopupToBeOpen: boolean;
  originalHasRewardsValue: boolean;
}
export interface FormulaOverideState {
  formulas: any[];
  creationMode: boolean;
  newLabel: string;
  newFormula: string;
  isEdited: boolean;
  defaultLabel: string;
}
export interface IHotelConfigProps {
  hotel: any;
  currentUser: string;
  deleteFormula: any;
  onHotelUpdate: (hotel: any) => void;
}
export interface IFormulaOveridesConfigProps {
  hotel: any;
  deleteFormula: any;
  onUpdate: (hotel: any) => void;
  isEdited: any;
  router: any;
  isAdmin: any;
}

export { rtTheme };
