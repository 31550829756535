import { Auth } from "aws-amplify";

interface SessionEventListener {
  onSessionWarning: (remainingSeconds: number) => void;
  onSessionTimeout: () => void;
  onCountdownTick?: (remainingSeconds: number) => void;
}

class SessionManager {
  private static instance: SessionManager;
  private timeoutId: NodeJS.Timeout | null = null;
  private warningTimeoutId: NodeJS.Timeout | null = null;
  private countdownIntervalId: NodeJS.Timeout | null = null;
  private readonly inactivityTimeout = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
  private readonly warningTimeout = 1.9 * 60 * 60 * 1000; // 1.9 hours in milliseconds (10 minutes before timeout)
  private eventListener: SessionEventListener | null = null;

  private constructor() {
    this.setupActivityListeners();
  }

  public async signOut(): Promise<void> {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  }

  public setEventListener(listener: SessionEventListener) {
    this.eventListener = listener;
  }

  public static getInstance(): SessionManager {
    if (!SessionManager.instance) {
      SessionManager.instance = new SessionManager();
    }
    return SessionManager.instance;
  }

  private setupActivityListeners(): void {
    const events = ["mousedown", "keydown", "scroll", "touchstart"];
    events.forEach((event) => {
      window.addEventListener(event, () => this.resetTimer());
    });
    this.resetTimer();
  }

  private resetTimer(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (this.warningTimeoutId) {
      clearTimeout(this.warningTimeoutId);
    }
    if (this.countdownIntervalId) {
      clearInterval(this.countdownIntervalId);
    }

    // Set warning timeout
    this.warningTimeoutId = setTimeout(() => {
      if (this.eventListener) {
        const remainingSeconds = Math.floor(
          (this.inactivityTimeout - this.warningTimeout) / 1000,
        );
        this.eventListener.onSessionWarning(remainingSeconds);

        // Start countdown interval
        let currentSeconds = remainingSeconds;
        this.countdownIntervalId = setInterval(() => {
          currentSeconds--;
          if (currentSeconds >= 0 && this.eventListener?.onCountdownTick) {
            this.eventListener.onCountdownTick(currentSeconds);
          }
        }, 1000);
      }
    }, this.warningTimeout);

    // Set final timeout
    this.timeoutId = setTimeout(() => {
      if (this.eventListener) {
        this.eventListener.onSessionTimeout();
      }
    }, this.inactivityTimeout);
  }

  public cleanup(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (this.warningTimeoutId) {
      clearTimeout(this.warningTimeoutId);
    }
    if (this.countdownIntervalId) {
      clearInterval(this.countdownIntervalId);
    }
  }
}

export default SessionManager;
