import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ThemeProvider,
} from "@mui/material";
import { baseTheme } from "../../mui-theme";

interface popupProps {
  isPopupOpen: boolean;
  title: string;
  content: React.ReactNode[];
  action: () => void;
  actionName: string;
  cancelAction?: () => void;
  cancelName?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  closeOnOutsideClick?: boolean;
  useCustomButtonStyle?: boolean;
}

class Popup extends Component<popupProps> {
  handleClose = () => {
    if (this.props.closeOnOutsideClick && this.props.cancelAction) {
      this.props.cancelAction();
    }
  };

  render() {
    return (
      <ThemeProvider theme={baseTheme}>
        <Dialog
          open={this.props.isPopupOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={this.props.maxWidth || "md"}
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent sx={{ padding: "32px" }}>
            {this.props.content.map((item, index) => (
              <DialogContentText
                id="alert-dialog-description"
                key={index}
                style={{ overflow: "visible", padding: "8px 0" }}
              >
                {item}
              </DialogContentText>
            ))}
          </DialogContent>
          <DialogActions>
            {this.props.cancelName !== undefined &&
              this.props.cancelAction !== undefined && (
                <Button
                  onClick={() => this.props.cancelAction?.()}
                  color="secondary"
                  variant="contained"
                  sx={{ mr: 1, mt: 1 }}
                >
                  {this.props.cancelName}
                </Button>
              )}
            <Button
              onClick={() => {
                this.props.action();
              }}
              id="primary-action"
              color="primary"
              variant="contained"
              sx={{ mr: 1, mt: 1 }}
            >
              {this.props.actionName}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
}

export default Popup;
