import { IYieldDay } from "../../models/yield.models";
import { Rowprop, rowType } from "../yield-sheet/rowProp";
import { CellValue } from "react-table";
import moment from "moment";
import { YieldSheetService } from "../services";
import { YieldApi } from "../../api/yield";
import { OtaApi } from "../../api/ota";
import { toast } from "react-toastify";

class SheetView {
  sheetHotel: any;
  rowProps: Rowprop;
  days: IYieldDay[];
  startDate: Date;
  endDate: Date;
  sheetData: any;
  compset: any[];
  compareOtaRate: any[];
  compareDemand: any[];

  constructor(
    sheetHotel: any,
    rowProps: Rowprop,
    startDate: Date,
    endDate: Date,
  ) {
    this.sheetHotel = sheetHotel;
    this.startDate = startDate;
    this.endDate = endDate;
    this.days = [];
    this.compset = [];
    this.compareOtaRate = [];
    this.compareDemand = [];
    this.rowProps = rowProps;
    this.sheetData = {};

    // Check URL for pickup date and market demand date
    const urlParams = new URLSearchParams(window.location.search);

    const pickupFromParam = urlParams.get("pickupFrom");
    if (pickupFromParam) {
      const pickupDate = moment(pickupFromParam).utc().set({
        hour: 12,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      if (pickupDate.isValid()) {
        this.rowProps.updatePickupFrom(pickupDate);
      }
    }

    const marketDemandParam = urlParams.get("marketDemandFrom");
    if (marketDemandParam) {
      const marketDemandDate = moment(marketDemandParam).utc().set({
        hour: 12,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      if (marketDemandDate.isValid()) {
        this.rowProps.updateMarketDemandPickup(marketDemandDate);
      }
    }
  }

  async refreshData() {
    try {
      this.days = await this.fetchFromBackend();
      const activeHotel = this.sheetHotel;

      const otaHotelId = parseInt(activeHotel.ota.hotelId);
      const hotelId = parseInt(activeHotel.hotelId);
      const pickupFrom = this.rowProps.pickupFrom;
      const marketDemandPickup = this.rowProps.marketDemand;
      const start = moment(this.startDate).format("YYYY-MM-DD");
      const end = moment(this.endDate).format("YYYY-MM-DD");

      const promises = [
        OtaApi.getRatesFromOTATable(otaHotelId, start, end),
        OtaApi.getDemandFromOTATable(otaHotelId, start, end),
        OtaApi.compareOTADataFromPickupData(
          otaHotelId,
          moment(marketDemandPickup).format("YYYY-MM-DD"),
          start,
          end,
        ),
      ];

      const [compSetArray, displayDemandData, otaCompareData] =
        await Promise.all(promises);

      const otaDemandCompare = otaCompareData["otaDemandCompare"];
      const otaRatesCompare = otaCompareData["otaRateCompare"];

      const allEmpty = [];

      for (const timestamp in otaDemandCompare) {
        if (
          Object.keys(otaDemandCompare[timestamp]).length === 0 &&
          Object.keys(otaRatesCompare[timestamp]).length === 0
        ) {
          allEmpty.push("empty");

          if (allEmpty.length === Object.keys(otaDemandCompare).length) {
            toast.warn(
              "No compset data available for the selected date. Please select another date.",
            );
          }
        }
      }

      let displayPickupData = {};
      if (
        activeHotel.cm.id === "guestline" ||
        activeHotel.pms.id === "hotsoft"
      ) {
        displayPickupData = await YieldApi.getFirstPickupData(
          hotelId,
          pickupFrom.valueOf(),
        );
      }
      let todayPickupData = {};
      if (
        activeHotel.pms.id === "hotsoft" &&
        activeHotel.pms.inventoryType === "manual"
      ) {
        todayPickupData = await YieldApi.getLastPickupData(
          hotelId,
          moment()
            .set({
              hours: 12,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            })
            .valueOf(),
        );
      }

      this.sheetData = this.prepareData(
        this.days,
        this.sheetHotel,
        compSetArray,
        displayDemandData,
        displayPickupData,
        todayPickupData,
        otaCompareData,
      );
    } catch (error) {
      console.error("Error:", error);
      // Handle the error appropriately
      throw error; // Rethrow the error to be caught by the caller if necessary
    }
  }

  fetchFromBackend(): Promise<IYieldDay[]> {
    return new Promise((resolve, reject) => {
      const start = this.startDate;
      const end = this.endDate;

      const promises: Promise<any>[] = [];
      promises.push(
        YieldApi.list(
          this.sheetHotel.hotelId,
          moment(start).format("YYYY-MM-DD"),
          moment(end).format("YYYY-MM-DD"),
        ),
      );

      Promise.all(promises)
        .then((payloads) => {
          const merged = [];
          for (const payload of payloads) {
            merged.push(...payload);
          }
          merged.sort((day1, day2) => {
            return day1.dateMidday - day2.dateMidday;
          });
          resolve(merged);
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  }

  notifyHotel() {
    return new Promise((resolve, reject) => {
      YieldSheetService.revanista.yieldsheet.notify
        .suggestedBR(this.sheetHotel.hotelId)
        .then((result: { suggestedCount: number }) => {
          resolve({ count: result.suggestedCount });
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  }

  prepareData(
    days: IYieldDay[],
    hotel: any,
    compSetArray: any,
    displayDemandData: any,
    displayPickupData: any,
    todayPickupData: any,
    otaCompareData: any,
  ) {
    let data: CellValue[][];
    data = [...new Array(this.rowProps.lastRowIndex + 1)].map(() => []);

    for (let i = 0; i < data.length; i++) {
      data[i] = data[i].slice(0, days.length);
    }
    const startIdx = 0;
    console.log("displayDemandData", displayDemandData);
    for (let dayIdx = 0, len = days.length; dayIdx < len; dayIdx++) {
      let otaupdateDate = "-";
      const sheetDay = days[dayIdx];
      const demandData = displayDemandData[days[dayIdx].dateMidday];

      const compSetData = compSetArray[days[dayIdx].dateMidday];

      const compareRateOfOta =
        otaCompareData["otaRateCompare"][days[dayIdx].dateMidday];
      const compareDemandOfOta =
        otaCompareData["otaDemandCompare"][days[dayIdx].dateMidday];

      console.log(
        compareRateOfOta,
        compSetData,
        compareDemandOfOta,
        demandData,
      );

      const col = startIdx + dayIdx + 1;
      const m = moment(sheetDay.dateMidday).valueOf();
      for (let i = 0; i <= this.rowProps.lastRowIndex; i++) {
        data[i][0] = this.rowProps.caption(i);

        let v;
        const rp = this.rowProps.get(i);
        if (!rp) {
          continue;
        }

        let totalLeftToSell = 0;
        if (
          hotel.pms.id === "hotsoft" &&
          hotel.pms.inventoryType === "manual"
        ) {
          totalLeftToSell = todayPickupData[m.toString()];
        } else {
          totalLeftToSell = Number(sheetDay.availability.leftToSell);
        }

        switch (rp.type) {
          case rowType.leftToSellUpdate:
            v = "";
            break;

          case rowType.derivedRateFiller:
            v = "";
            break;

          case rowType.suggestedBaseRate:
            v =
              sheetDay && sheetDay.suggestedBaseRate
                ? sheetDay.suggestedBaseRate
                : "";
            break;

          case rowType.autoSuggested:
            v = hotel.computeSuggestion(sheetDay);
            break;

          case rowType.actualBaseRate:
            v = sheetDay.actualBaseRate;
            break;

          case rowType.actualOWSBaseRate:
            v = sheetDay.actualOWSBaseRate;
            break;

          case rowType.leftToSell:
            if (hotel.cm.id === "guestline" || hotel.pms.id === "hotsoft") {
              if (typeof totalLeftToSell == "undefined") {
                v = "-";
              } else {
                v = totalLeftToSell;
              }
            } else {
              // Refers to the Left to sell updates row
              v = sheetDay.availability.leftToSell;
            }
            break;

          case rowType.occupancy:
            if (isNaN(totalLeftToSell)) {
              v = "-";
            } else {
              v =
                Math.round(
                  ((hotel.meta.totalRooms - totalLeftToSell) /
                    hotel.meta.totalRooms) *
                    100,
                ) + " %";
            }
            break;

          case rowType.ratePlan:
            if (rp.isManual) {
              v =
                sheetDay.manualRates && sheetDay.manualRates[rp.roomTypeId]
                  ? sheetDay.manualRates[rp.roomTypeId]?.[rp.ratePlanId]
                    ? sheetDay.manualRates[rp.roomTypeId]?.[rp.ratePlanId].rate
                    : "-"
                  : "-";
            } else {
              v = sheetDay.derivedRates[rp.roomTypeId]
                ? sheetDay.derivedRates[rp.roomTypeId]?.[rp.ratePlanId]
                  ? sheetDay.derivedRates[rp.roomTypeId]?.[rp.ratePlanId].rate
                  : "-"
                : "-";
            }
            if (v !== "-") {
              v = Number(v) / 100;
              if (v % 1 > 0) {
                v = v.toFixed(2);
              }
            }
            break;

          case rowType.roomType:
            if (
              hotel.meta.isPublishingInventory === "false" &&
              hotel.cm.id !== "guestline"
            ) {
              const currency = new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: hotel.info.currencyCode,
              });
              let result = "-";
              if (
                typeof sheetDay.availabilities[rp.roomTypeId] !== "undefined"
              ) {
                let formula = hotel.roomTypes[rp.roomTypeId].formula;
                const BR = sheetDay.actualBaseRate;
                formula = formula.replace("BR", BR);
                const evaluatedFormula = eval(formula);
                result = currency.format(evaluatedFormula);
              }

              v = result;
            } else {
              v =
                typeof sheetDay.availabilities[rp.roomTypeId] != "undefined"
                  ? sheetDay.availabilities[rp.roomTypeId].leftToSell
                  : "-";
            }
            break;

          case rowType.pickup:
            if (
              sheetDay.availability?.lastUpdateLeftTosell !== null &&
              sheetDay.availability?.leftToSell !== null
            ) {
              v =
                sheetDay.availability.lastUpdateLeftTosell -
                sheetDay.availability.leftToSell;
              v = isNaN(v) ? "-" : v;
            }
            break;

          case rowType.pickupFrom: {
            let sheetDayLeftToSell = 0;
            if (
              hotel.pms.id === "hotsoft" &&
              hotel.pms.inventoryType === "manual"
            ) {
              if (typeof displayPickupData[m.toString()] == "undefined") {
                v = "-";
              } else {
                v =
                  displayPickupData[m.toString()] -
                  todayPickupData[m.toString()];
                v = isNaN(v) ? "-" : v;
              }
            } else {
              for (const key in sheetDay.availabilities) {
                if (key !== "stat") {
                  sheetDayLeftToSell =
                    sheetDayLeftToSell +
                    Number(sheetDay.availabilities[key].leftToSell);
                }
              }
              if (typeof displayPickupData[m.toString()] == "undefined") {
                v = "-";
              } else {
                v = displayPickupData[m.toString()] - sheetDayLeftToSell;
              }
            }
            break;
          }

          case rowType.pickupDateFrom:
            v = sheetDay.availability.pickupDate
              ? moment(sheetDay.availability.pickupDate).format("D MMM")
              : "";
            break;

          case rowType.lastUpdateLeftTosell:
            v =
              sheetDay.availability?.lastUpdateLeftTosell !== null
                ? sheetDay.availability.lastUpdateLeftTosell
                : "";
            break;

          case rowType.marketDemand: {
            v =
              demandData && demandData["demand"] !== undefined
                ? Math.round(Number(demandData["demand"]) * 100) + " %"
                : "-";
            const compareDemand =
              compareDemandOfOta && compareDemandOfOta["demand"] !== undefined
                ? compareDemandOfOta["demand"]
                : "-";
            this.compareDemand[col] = this.compareDemand[col]
              ? this.compareDemand[col]
              : {};
            this.compareDemand[col] = compareDemand;
            console.log("col", col, this.compareDemand[col]);
            break;
          }
          case rowType.ota: {
            const r =
              compSetData !== undefined ? compSetData[rp.hotelName] : undefined;
            this.compset[col] = this.compset[col] ? this.compset[col] : {};
            this.compset[col][rp.hotelName] = r;
            v = this.compset[col][rp.hotelName];
            const compareRate =
              compareRateOfOta && compareRateOfOta !== undefined
                ? compareRateOfOta[rp.hotelName]
                : undefined;
            this.compareOtaRate[col] = this.compareOtaRate[col]
              ? this.compareOtaRate[col]
              : {};
            this.compareOtaRate[col][rp.hotelName] = compareRate;
            if (otaupdateDate === "-") {
              otaupdateDate = r && r.updatedDate ? r.updatedDate : "-";
            } else if (r && r.updatedDate) {
              if (moment(r.updatedDate).isAfter(otaupdateDate)) {
                otaupdateDate = r.updatedDate;
              }
            }
            break;
          }
          case rowType.lastUpdateOta:
            if (otaupdateDate === "-") {
              v = "-";
            } else {
              v = moment(otaupdateDate).format("D MMM");
            }
            break;
        }
        data[i][col] = v;
      }
    }

    return data;
  }
}
export default SheetView;
