import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { Component, RefObject, createRef } from "react";
import List from "@mui/material/List";
import { YieldSheetService, ConfigService } from "../../services";
import { UserApi } from "../../../api/user";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ResetIcon from "@mui/icons-material/RotateLeft";
import { toast } from "react-toastify";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Grid,
  Button,
  Chip,
  ListItemIcon,
  Checkbox,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import {
  Container,
  CardActions,
  Card,
  Typography,
  IconButton,
  Tooltip,
  ListItemText,
} from "@material-ui/core";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import SectionTitle from "../../elements/sectionTitle";
import { CSSTransition } from "react-transition-group";
import { appStore } from "../../store";
import withRouter from "../../withRouter";
import Popup from "../../../reusable-components/popup/popup";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";

const theme: any = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },
    MuiCard: {
      root: {
        maxWidth: "350px",
        width: "30%",
        float: "left",
        margin: "10px",
        fontSize: "1em",
      },
    },
    MuiCardContent: {
      root: {
        fontSize: "1em",
      },
    },
    MuiTypography: {
      root: {
        float: "left",
      },
    },
    MuiTextField: {
      root: {
        display: "inline-block",
      },
    },
    MuiSvgIcon: {
      root: {
        float: "right",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-bock",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          fontWeight: "bolder",
        },
      },
    },
  },
});

interface State {
  userList: { email: string }[];
  adminList: { email: string }[];
  isSubscribePanelOpened: boolean;
  isCreateUserPanelOpened: boolean;
  isCreateAdminPanelOpened: boolean;
  subscriptionList: string[];
  isUserDeleteDialogOpened: boolean;
  isAdminDeleteDialogOpened: boolean;
  selectedSubscriptionusers: string[];
  userTodelete: string;
  isOverflowAdmin: boolean[];
  isOverflowUser: boolean[];
  isOverflowNoti: boolean[];
  isUpdated: boolean;
  isNotifyUpdated: boolean;
  updatingUser: string[];
  isLoading: boolean;
  isAdminUser: boolean;
}

export interface IUserProps {
  activeHotel: any;
  router: any;
}

class Users extends Component<IUserProps> {
  private mainDivRefsAdmin: RefObject<HTMLDivElement>[] = [];
  private mainDivRefsUser: RefObject<HTMLDivElement>[] = [];
  private mainDivRefsNoti: RefObject<HTMLDivElement>[];
  readonly state: State = {
    userList: [],
    adminList: [],
    subscriptionList: [],
    userTodelete: "",
    isSubscribePanelOpened: false,
    isCreateUserPanelOpened: false,
    isCreateAdminPanelOpened: false,
    isUserDeleteDialogOpened: false,
    isAdminDeleteDialogOpened: false,
    selectedSubscriptionusers: [],
    isOverflowAdmin: [],
    isOverflowUser: [],
    isOverflowNoti: [],
    isUpdated: false,
    isNotifyUpdated: false,
    updatingUser: [],
    isLoading: true,
    isAdminUser: false,
  };
  currentUser: string;
  users: { email: string }[];

  constructor(props: any) {
    super(props);
    this.currentUser = appStore.meta._user.username;
    this.users = [];

    this.state.isAdminUser =
      appStore.meta._user.permissions.admin[0] === "*" ? true : false;

    if (typeof this.props.activeHotel?.hotelId != undefined) {
      this.fetchUser().then((users) => {
        this.mainDivRefsUser = users.users.map(() =>
          createRef<HTMLDivElement>(),
        );
        this.state.isOverflowUser = users.users.map(() => false);

        this.mainDivRefsAdmin = users.admins.map(() =>
          createRef<HTMLDivElement>(),
        );
        this.state.isOverflowAdmin = users.admins.map(() => false);
      });
    }

    this.mainDivRefsNoti = this.state.subscriptionList.map(() =>
      createRef<HTMLDivElement>(),
    );

    this.state.isOverflowNoti = this.state.subscriptionList.map(() => false);
  }

  createAdmin = async (values: { email: string }) => {
    await toast.promise(
      (async () => {
        const response = await UserApi.createHotelUser({
          hotelIds: [this.props.activeHotel.hotelId],
          userEmail: values.email,
          isAdmin: true,
        });

        if (response.status === "UserToAdmin") {
          throw new Error(
            `${values.email} has been converted to an administrator`,
          );
        } else if (response.status === "UserIsAdmin") {
          throw new Error(`${values.email} is already an administrator`);
        } else if (response.status === "AdminToUser") {
          throw new Error(`${values.email} is already an administrator`);
        } else if (response.status === "NoChange") {
          throw new Error(`${values.email} is already an administrator`);
        }

        await this.refreshUserList();
        this.setState({
          isCreateAdminPanelOpened: false,
        });
        return response;
      })(),
      {
        pending: "Creating administrator...",
        success: {
          render: () => `Invitation sent to ${values.email}`,
        },
        error: {
          render: ({ data }) => {
            const error = data as Error;
            return error.message;
          },
        },
      },
    );
  };

  toggleCreateUserPanel = () => {
    this.setState({
      isCreateUserPanelOpened: !this.state.isCreateUserPanelOpened,
    });
  };

  toggleCreateAdminPanel = () => {
    this.setState({
      isCreateAdminPanelOpened: !this.state.isCreateAdminPanelOpened,
    });
  };

  createUser = async (values: { email: string }) => {
    await toast.promise(
      (async () => {
        const response = await UserApi.createHotelUser({
          hotelIds: [this.props.activeHotel.hotelId],
          userEmail: values.email,
          isAdmin: false,
        });

        if (response.status === "AdminToUser") {
          throw new Error(
            `${values.email} is an administrator and cannot be converted to a user`,
          );
        } else if (response.status === "NoChange") {
          throw new Error(`${values.email} is already a user`);
        }

        await this.refreshUserList();
        this.setState({ isCreateUserPanelOpened: false });
        return response;
      })(),
      {
        pending: "Creating user...",
        success: {
          render: () => `Invitation sent to ${values.email}`,
        },
        error: {
          render: ({ data }) => {
            const error = data as Error;
            if (
              error.message.includes("administrator") ||
              error.message.includes("already a user")
            ) {
              return error.message;
            }
            return `Error creating user: ${error.message}`;
          },
        },
      },
    );
  };

  refreshUserList = async () => {
    const allUserList = await this.fetchUser();
    this.users = allUserList;
    this.setState({
      userList: allUserList.users,
      adminList: allUserList.admins,
    });
  };

  resetUserPassword = async (email: string) => {
    await YieldSheetService.revanista.hotel.resetUserPassword(
      this.props.activeHotel.hotelId,
      email,
    );
    await this.refreshUserList();
    toast.success("password reset");
  };

  deleteUser = async () => {
    await YieldSheetService.revanista.hotel.deleteUser(
      [this.props.activeHotel.hotelId],
      this.state.userTodelete,
    );
    if (this.state.subscriptionList.includes(this.state.userTodelete)) {
      const toDeleteFromSubscriptionList = this.state.subscriptionList.filter(
        (data: string) => data !== this.state.userTodelete,
      );
      console.log("found");

      await this.updateSubcriptionList(toDeleteFromSubscriptionList);
    }
    await this.refreshUserList();
    this.setState({
      isUserDeleteDialogOpened: false,
      isAdminDeleteDialogOpened: false,
    });
    toast.success("User deleted");
  };

  removeFromSubscription = (email: string) => {
    const updated = this.state.subscriptionList.filter((elem: string) => {
      return elem !== email;
    });
    this.updateSubcriptionList(updated);
  };

  addToSubscription = async () => {
    await toast.promise(
      (async () => {
        this.setState({ isNotifyUpdated: false });
        const updated = this.state.selectedSubscriptionusers.concat(
          this.state.subscriptionList,
        );
        await this.updateSubcriptionList(updated);
        return updated;
      })(),
      {
        pending: "Adding users to notification list...",
        success: "Users successfully added to notification list",
        error: {
          render: ({ data }) => {
            const error = data as Error;
            return `Error adding users to notification list: ${error.message}`;
          },
        },
      },
    );
  };

  updateSubcriptionList = async (list: string[]) => {
    await YieldSheetService.revanista.hotel.editSubscriptionList({
      hotelId: this.props.activeHotel.hotelId,
      contactEmails: list,
    });
    this.setState({
      subscriptionList: list,
      selectedSubscriptionusers: [],
      isSubscribePanelOpened: false,
    });
    await ConfigService.revanista.hotel.updateConfig(
      this.props.activeHotel.hotelId,
    );
    // this.props.onHotelUpdate(this.props.activeHotel);
    await ConfigService.revanista.hotel.updateConfig(
      this.props.activeHotel.hotelId,
    );
    //avoid hardrefreshing the whole scren
  };

  openDeleteDialog = (userEmail: string) => {
    this.setState({
      userTodelete: userEmail,
      isUserDeleteDialogOpened: true,
    });
  };

  openDeleteAdminDialog = (userEmail: string) => {
    this.setState({
      userTodelete: userEmail,
      isAdminDeleteDialogOpened: true,
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      isUserDeleteDialogOpened: false,
      isAdminDeleteDialogOpened: false,
    });
  };

  cancelAppend = () => {
    this.setState({
      selectedSubscriptionusers: [],
      isSubscribePanelOpened: false,
    });
  };

  toggleSubscribePanel = () => {
    this.setState({
      isSubscribePanelOpened: !this.state.isSubscribePanelOpened,
    });
  };

  handleSubscriberSelection(event: SelectChangeEvent<string[]>) {
    const value = event.target.value;
    const existingUserInList = this.state.subscriptionList;

    const allUserList = this.state.adminList.concat(this.state.userList);

    const options = allUserList.filter(
      (user) => !existingUserInList.includes(user.email),
    );

    const emailFromOptions = options.map(
      (data: { email: string }) => data.email,
    );

    if (value[value.length - 1] === "all") {
      this.setState({
        selectedSubscriptionusers:
          this.state.selectedSubscriptionusers.length ===
          emailFromOptions.length
            ? []
            : emailFromOptions,
      });
      return;
    }
    this.setState({
      selectedSubscriptionusers: value as string[],
    });

    if (value) {
      this.setState({ isNotifyUpdated: true });
    }
  }

  fetchUser = async () => {
    const res = await YieldSheetService.revanista.hotel.listUser(
      this.props.activeHotel.hotelId,
    );
    return res;
  };

  getAdminListCard(
    user: { email: string; fullControl?: boolean; status?: string },
    index: number,
  ) {
    const email: string = user.email;
    const { isOverflowAdmin } = this.state;
    return (
      <Card key={email}>
        <div
          ref={this.mainDivRefsAdmin[index]}
          className={
            isOverflowAdmin[index] ? "message_ticker_new" : "message_ticker"
          }
        >
          <h5
            style={{
              fontWeight: "bold",
              marginTop: 10,
              marginLeft: 15,
              fontSize: "1em",
            }}
          >
            {email}
          </h5>
        </div>
        {user.fullControl && (
          <SupervisedUserCircleIcon style={{ fill: "#3f51b5" }} />
        )}
        {user.status && user.status !== "CONFIRMED" && (
          <HourglassFullIcon style={{ fill: "crimson" }} />
        )}

        <CardActions>
          {this.state.isAdminUser &&
            user.status &&
            user.status !== "CONFIRMED" && (
              <Tooltip title="Reset Password">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    this.resetUserPassword(email);
                  }}
                >
                  <ResetIcon style={{ fill: "black" }} />
                </IconButton>
              </Tooltip>
            )}
          {this.state.isAdminUser && (
            <Tooltip title="Remove">
              <IconButton
                id={`delete-admin-${email}-button`}
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  this.openDeleteAdminDialog(email);
                }}
              >
                <DeleteIcon style={{ fill: "crimson" }} />
              </IconButton>
            </Tooltip>
          )}
        </CardActions>
      </Card>
    );
  }

  getUserListCard(
    user: { email: string; fullControl?: boolean; status?: string },
    index: number,
  ) {
    const email: string = user.email;
    const { isOverflowUser } = this.state;
    return (
      <Card key={email}>
        <div
          ref={this.mainDivRefsUser[index]}
          className={
            isOverflowUser[index] ? "message_ticker_new" : "message_ticker"
          }
        >
          <h5
            style={{
              fontWeight: "bold",
              marginTop: 10,
              marginLeft: 15,
              fontSize: "1em",
            }}
          >
            {email}
          </h5>
        </div>
        {user.fullControl && (
          <SupervisedUserCircleIcon style={{ fill: "#3f51b5" }} />
        )}
        {user.status &&
          user.status !== "CONFIRMED" &&
          this.state.isAdminUser && (
            <HourglassFullIcon style={{ fill: "crimson" }} />
          )}
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
        ></Typography>

        <CardActions>
          {this.state.isAdminUser &&
            user.status &&
            user.status !== "CONFIRMED" && (
              <Tooltip title="Reset Password">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    this.resetUserPassword(email);
                  }}
                >
                  <ResetIcon style={{ fill: "black" }} />
                </IconButton>
              </Tooltip>
            )}
          {this.state.isAdminUser && (
            <Tooltip title="Remove">
              <IconButton
                id={`delete-user-${email}-button`}
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  this.openDeleteDialog(email);
                }}
              >
                <DeleteIcon style={{ fill: "crimson" }} />
              </IconButton>
            </Tooltip>
          )}
        </CardActions>
      </Card>
    );
  }

  getUserSubscriptionCard(user: { email: string }, index: number) {
    const email: string = typeof user == "string" ? user : user.email;
    const { isOverflowNoti } = this.state;
    return (
      <Card key={email}>
        <div
          ref={this.mainDivRefsNoti[index]}
          className={
            isOverflowNoti[index] ? "message_ticker_new" : "message_ticker"
          }
        >
          <h5
            style={{
              fontWeight: "bold",
              marginTop: 10,
              marginLeft: 15,
              fontSize: "1em",
              height: "15",
            }}
          >
            {email}
          </h5>
        </div>
        {this.state.isAdminUser && (
          <CardActions>
            <Tooltip title="unsubscribe">
              <IconButton
                id={`unsubscribe-user-${email}-button`}
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  this.removeFromSubscription(email);
                }}
              >
                <DeleteIcon style={{ fill: "crimson" }} />
              </IconButton>
            </Tooltip>
          </CardActions>
        )}
      </Card>
    );
  }

  async componentDidMount() {
    if (this.props.activeHotel.info) {
      this.setState({
        subscriptionList: this.props.activeHotel.info.contactEmails,
      });
      this.handleSubscriberSelection =
        this.handleSubscriberSelection.bind(this);
      try {
        await this.refreshUserList();
      } catch (err) {
        console.log("error fetching user list", err);
      }
      this.setState({ isLoading: false });

      this.checkOverflow();
      window.addEventListener("resize", this.checkOverflow);
      return () => {
        window.removeEventListener("resize", this.checkOverflow);
      };
    }
  }

  async componentDidUpdate(prevProps: IUserProps) {
    if (this.props.activeHotel !== prevProps.activeHotel) {
      this.setState({
        subscriptionList: this.props.activeHotel.info.contactEmails,
      });
      this.handleSubscriberSelection =
        this.handleSubscriberSelection.bind(this);
      try {
        await this.refreshUserList();
      } catch (err) {
        console.log("error fetching user list", err);
      }
      this.setState({ isLoading: false });

      this.checkOverflow();
      window.addEventListener("resize", this.checkOverflow);
      return () => {
        window.removeEventListener("resize", this.checkOverflow);
      };
    }
  }

  checkOverflow = () => {
    const updatedIsOverflowAdmin = this.mainDivRefsAdmin.map((ref) => {
      return ref.current && ref.current.scrollWidth > ref.current.clientWidth;
    });
    this.setState({ isOverflowAdmin: updatedIsOverflowAdmin });

    const updatedIsOverflowUser = this.mainDivRefsUser.map((ref) => {
      return ref.current && ref.current.scrollWidth > ref.current.clientWidth;
    });
    this.setState({ isOverflowUser: updatedIsOverflowUser });

    const updatedIsOverflowNoti = this.mainDivRefsNoti.map((ref) => {
      return ref.current && ref.current.scrollWidth > ref.current.clientWidth;
    });
    this.setState({ isOverflowNoti: updatedIsOverflowNoti });
  };

  render() {
    const existingUserInList = this.state.subscriptionList;

    const allUserList = this.state.adminList.concat(this.state.userList);

    const filteredUsers = allUserList.filter(
      (user) => !existingUserInList.includes(user.email),
    );

    const isAllSelected =
      (allUserList.length > 0 &&
        this.state.subscriptionList.length === allUserList.length) ||
      (filteredUsers.length > 0 &&
        filteredUsers.length === this.state.selectedSubscriptionusers.length);

    const validationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Mandatory field"),
    });

    return (
      <MuiThemeProvider theme={theme}>
        <Backdrop
          className={theme}
          open={this.state.isLoading}
          invisible={true}
        >
          <CircularProgress color="inherit" id="circular-progress" />
        </Backdrop>
        <div className="content">
          {appStore.meta._user.permissions.admin[0] !== "*" && (
            <p
              style={{ color: "red", fontWeight: "bold", textAlign: "center" }}
            >
              This page is admin locked. Please contact us should you need
              something changed.
            </p>
          )}

          {this.state.isAdminUser && (
            <div>
              <SectionTitle {...{ value: "Administrators List" }} />
              <br />

              {!this.state.isCreateAdminPanelOpened && (
                <Button
                  id="create-admin-button"
                  aria-label="split"
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={this.toggleCreateAdminPanel}
                  style={{
                    width: "50px",
                    height: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <PersonAddIcon />
                </Button>
              )}
              <CSSTransition
                in={this.state.isCreateAdminPanelOpened}
                appear={true}
                exit={true}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    await this.createAdmin(values);
                    setSubmitting(false);
                    resetForm();
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item sm={3}>
                          <FormControl>
                            <TextField
                              id="email"
                              name="email"
                              label="Email"
                              variant="outlined"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              error={touched.email && Boolean(errors.email)}
                              helperText={touched.email && errors.email}
                              style={{ marginLeft: 25 }}
                              inputProps={{ maxLength: 40 }}
                            />
                            <div
                              style={{
                                maxHeight: "55px",
                                display: "inline-block",
                                marginLeft: 25,
                              }}
                            >
                              <Button
                                id="create-admin-cancel"
                                onClick={this.toggleCreateAdminPanel}
                                variant="contained"
                                style={{
                                  backgroundColor: "#808080",
                                  color: "#ffffff",
                                  marginRight: "10px",
                                  marginTop: "10px",
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="submit"
                                id="create-admin-submit"
                                variant="contained"
                                disabled={
                                  isSubmitting ||
                                  !values.email ||
                                  Boolean(errors.email)
                                }
                                style={{
                                  backgroundColor:
                                    isSubmitting ||
                                    !values.email ||
                                    Boolean(errors.email)
                                      ? "#ffcdd2"
                                      : "#ce0e29",
                                  color: "#ffffff",
                                  marginRight: "10px",
                                  marginTop: "10px",
                                }}
                              >
                                Create
                              </Button>
                            </div>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </CSSTransition>

              <br />
              <Container>
                <List>
                  {this.state.adminList.map((user, index) =>
                    this.getAdminListCard(user, index),
                  )}
                </List>
              </Container>

              <br />
            </div>
          )}

          <Divider></Divider>

          <SectionTitle {...{ value: "Users List" }} />
          {this.state.isAdminUser && (
            <>
              <br />
              {!this.state.isCreateUserPanelOpened && (
                <Button
                  id="create-user-button"
                  aria-label="split"
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={this.toggleCreateUserPanel}
                  style={{
                    width: "50px",
                    height: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <PersonAddIcon />
                </Button>
              )}
            </>
          )}

          <CSSTransition
            in={this.state.isCreateUserPanelOpened}
            appear={true}
            exit={true}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                await this.createUser(values);
                setSubmitting(false);
                resetForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item sm={3}>
                      <FormControl>
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          style={{ marginLeft: 25 }}
                          inputProps={{ maxLength: 40 }}
                        />
                        <div
                          style={{
                            maxHeight: "55px",
                            display: "inline-block",
                            marginLeft: 25,
                          }}
                        >
                          <Button
                            id="create-user-cancel"
                            onClick={this.toggleCreateUserPanel}
                            variant="contained"
                            style={{
                              backgroundColor: "#808080",
                              color: "#ffffff",
                              marginRight: "10px",
                              marginTop: "10px",
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            id="create-user-submit"
                            variant="contained"
                            disabled={
                              isSubmitting ||
                              !values.email ||
                              Boolean(errors.email)
                            }
                            style={{
                              backgroundColor:
                                isSubmitting ||
                                !values.email ||
                                Boolean(errors.email)
                                  ? "#ffcdd2"
                                  : "#ce0e29",
                              color: "#ffffff",
                              marginRight: "10px",
                              marginTop: "10px",
                            }}
                          >
                            Create
                          </Button>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CSSTransition>

          <br />
          <Container>
            <List>
              {this.state.userList.map((user, index) =>
                this.getUserListCard(user, index),
              )}
            </List>
          </Container>
          <br />
          <Divider></Divider>
          <SectionTitle {...{ value: "Notification List" }} />
          {this.state.isAdminUser && (
            <>
              <br />

              {!this.state.isSubscribePanelOpened && (
                <Button
                  id="subscribe-user-button"
                  aria-label="split"
                  variant="contained"
                  color="primary"
                  component="span"
                  disabled={
                    appStore.meta._user.permissions.admin[0] !== "*"
                      ? true
                      : false
                  }
                  onClick={() => this.toggleSubscribePanel()}
                  style={{
                    width: "50px",
                    height: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <PersonAddIcon />
                </Button>
              )}
            </>
          )}

          <CSSTransition
            in={this.state.isSubscribePanelOpened}
            appear={true}
            exit={true}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <Grid container spacing={3}>
              <Grid item sm={3}>
                <FormControl variant="outlined">
                  <InputLabel
                    id="mutiple-select-label"
                    style={{ width: "250px" }}
                  >
                    Select users
                  </InputLabel>
                  <Select
                    style={{ width: "250px" }}
                    labelId="mutiple-select-label"
                    label="Select users"
                    name="selectUsers"
                    multiple
                    value={this.state.selectedSubscriptionusers}
                    onChange={(event) => this.handleSubscriberSelection(event)}
                    renderValue={(selected: string[]) => (
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {(selected as string[]).map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </div>
                    )}
                  >
                    <MenuItem value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllSelected}
                          indeterminate={
                            this.state.selectedSubscriptionusers.length > 0 &&
                            this.state.selectedSubscriptionusers.length <
                              filteredUsers.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {filteredUsers.map((option: { email: string }) => (
                      <MenuItem key={option.email} value={option.email}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              this.state.selectedSubscriptionusers.indexOf(
                                option.email,
                              ) > -1
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={option.email} />
                      </MenuItem>
                    ))}
                  </Select>
                  <div
                    style={{
                      maxHeight: "55px",
                      display: "inline-block",
                      marginLeft: 25,
                    }}
                  >
                    <Button
                      id="subscribe-user-cancel"
                      onClick={this.cancelAppend}
                      variant="contained"
                      style={{
                        backgroundColor: "#808080",
                        color: "#ffffff",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      id="subscribe-user-submit"
                      onClick={this.addToSubscription}
                      variant="contained"
                      disabled={
                        this.state.selectedSubscriptionusers.length === 0
                      }
                      style={{
                        backgroundColor:
                          this.state.selectedSubscriptionusers.length === 0
                            ? "#ffcdd2"
                            : "#ce0e29",
                        color: "#ffffff",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </CSSTransition>
          <br />
          <Container>
            <List>
              {this.state.subscriptionList.map((user: any, index: any) =>
                this.getUserSubscriptionCard(user, index),
              )}
            </List>
          </Container>
        </div>

        <Popup
          isPopupOpen={this.state.isUserDeleteDialogOpened}
          title="Remove User"
          content={[
            `Are you sure you want to remove "${this.state.userTodelete}" from this hotel?`,
            "Note that if this user has access to multiple properties, they will only be removed from this hotel.",
          ]}
          action={this.deleteUser}
          actionName="Remove user"
          cancelAction={this.closeDeleteDialog}
          cancelName="Cancel"
        />

        <Popup
          isPopupOpen={this.state.isAdminDeleteDialogOpened}
          closeOnOutsideClick={true}
          title="Remove User"
          content={[
            `Are you sure you want to remove  admin " ${this.state.userTodelete} " from Revanista?`,
          ]}
          action={this.deleteUser}
          actionName="Remove admin"
          cancelAction={this.closeDeleteDialog}
          cancelName="Cancel"
        />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  activeHotel: state.hotelData.activeHotel,
});

export default connect(mapStateToProps)(withRouter(Users));
