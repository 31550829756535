import { store } from "redux/store";
import { setActiveHotel, setHotels } from "redux/Slice/hotelSlice";
import { setError } from "redux/Slice/state";
import { YieldApi } from "api/yield";

const revanista = {
  initialize: async () => {
    try {
      const userHotels = await YieldApi.getUsersHotelsMeta();
      if (userHotels !== null && userHotels.length !== 0) {
        store.dispatch(setHotels(userHotels));
      }

      const pathname = window.location.pathname;
      const parts = pathname.split("/");
      const firstRoute = parseInt(parts[1]);

      const hotelData = await YieldApi.getHotelConfig(firstRoute);

      store.dispatch(setActiveHotel(hotelData));

      return userHotels;
    } catch (err: unknown) {
      console.log("error", err);
      store.dispatch(setError(true));
      throw err;
    }
  },
};

const AppService = {
  revanista: revanista,
};

export { AppService };
