import React, { Component, RefObject } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import moment from "moment";

import { YieldSheetService } from "../../services";
import ExecSummaryItem from "./execSummaryItem";
import commonfileUtils from "../common";
import { appStore } from "../../store";
import withRouter from "../../withRouter";
import { YieldApi } from "api/yield";

import { List, Button, Container, TextField } from "@mui/material";
import { AttachFile, Delete, Send } from "@mui/icons-material";

const avatarsColors = [
  "crimson",
  "blueviolet",
  "brown",
  "darkblue",
  "darkcyan",
];

interface State {
  items: string[];
  editedText: string;
  editedAttachments: { name: string; id: string }[];
  isEdited: boolean;
  captureTextBox: string;
}
export interface IExecSummaryProps {
  activeHotel: any;
  router: any;
}

class ExecSummary extends Component<IExecSummaryProps> {
  readonly state: State = {
    items: [],
    editedText: "",
    editedAttachments: [],
    isEdited: false,
    captureTextBox: "",
  };

  currentUser: string;
  editTextBoxref: RefObject<any>;
  newEntry: any;
  avatarColor: any;

  constructor(props: any) {
    super(props);

    this.currentUser = appStore.meta._user.username;
    this.editTextBoxref = React.createRef();
    if (typeof this.props.activeHotel.hotelId != undefined) {
      this.fetchItems();
    }
  }

  componentDidMount() {
    if (typeof this.props.activeHotel.hotelId != undefined) {
      this.fetchItems();
    }
  }

  componentDidUpdate(prevProps: IExecSummaryProps) {
    if (prevProps.activeHotel !== this.props.activeHotel) {
      this.fetchItems();
    }
  }

  handleLeavePage = (event: BeforeUnloadEvent) => {
    event.returnValue = "Are you sure you want to leave?";
  };

  fetchItems = async () => {
    try {
      const items = await YieldApi.getExecSummaryItems(
        this.props.activeHotel.hotelId,
      );
      const userList = [];
      for (const i of items) {
        if (userList.indexOf(i.origin) === -1) {
          userList.push(i.origin);
        }
      }
      const avatarColor: { [key: string]: string } = {};
      let i = 0;
      for (const u of userList) {
        avatarColor[u] =
          avatarsColors[
            i < avatarsColors.length
              ? i
              : i - ((avatarsColors.length * i) % avatarsColors.length)
          ];
        i++;
      }
      this.avatarColor = avatarColor;
      this.setState({ items, editedAttachments: [] });
    } catch (err) {
      console.log(err);
    }
  };

  appendFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const a = this.state.editedAttachments;

    const p: Promise<any>[] = [];
    const files = e.target.files ? Array.from(e.target.files) : [];
    for (const file of files) {
      const tokens = file.name.split(".");
      p.push(
        YieldSheetService.revanista.files
          .getUploadLink(
            this.props.activeHotel.hotelId,
            file.type,
            tokens[tokens.length - 1],
            file.name,
          )
          .then((res: any) => {
            const url = res.presignedUrl;
            const filename = res.objectKey;
            const reader = new FileReader();
            reader.onload = function (e: any) {
              const blob = new Blob([new Uint8Array(e.target.result)], {
                type: file.type,
              });
              YieldSheetService.revanista.files
                .upload(url, file.type, blob)
                .catch((err: any) => {
                  toast.error("Error uploading " + file.name);
                  console.log(err);
                });
            };
            reader.readAsArrayBuffer(file);
            return { name: file.name.replace(/^.*[\\/]/, ""), id: filename };
          }),
      );
    }
    Promise.all(p).then((v: any) => {
      if (v !== undefined) {
        a.push(...v);
      }

      if (a.length > 0) {
        this.setState({ isEdited: true });
      }

      this.setState({ editedAttachments: a });
    });
  };

  deletefile = async (id: string, name: string) => {
    try {
      await commonfileUtils.deleteFile(this.props.activeHotel.hotelId, id);
      const a = this.state.editedAttachments;
      const filtered = a.filter((e) => e.name !== name);
      this.setState({ editedAttachments: filtered });
    } catch (err) {
      console.log(err);
      toast.error("Error deleting file");
    }
  };

  AddItem = () => {
    const text = this.editTextBoxref.current.value;
    this.editTextBoxref.current.value = "";
    this.setState({ isEdited: false });

    const item = {
      hotelId: this.props.activeHotel.hotelId,
      timestamp: moment().valueOf(),
      description: text,
      attachments: this.state.editedAttachments || [],
    };
    YieldSheetService.revanista.hotel.putExecSummaryItem(item).then(() => {
      this.fetchItems();
    });
  };

  handleOnChange = (event: any) => {
    console.log(event.target.value);
    this.setState({ captureTextBox: event.target.value });
  };

  render() {
    return (
      <div
        className="content"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Container
          style={{
            flex: 1,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <List>
            {this.state.items.map((item: any) => {
              return (
                <ExecSummaryItem
                  hotelId={this.props.activeHotel.hotelId}
                  item={item}
                  avatarColors={this.avatarColor}
                />
              );
            })}
          </List>
        </Container>
        <br />
        <Container style={{ marginBottom: "5vh" }}>
          {
            <Container style={{ paddingRight: 0 }}>
              <Container
                style={{
                  height: 100,
                  display: "inline-table",
                  paddingRight: 0,
                  paddingLeft: 35,
                }}
              >
                <TextField
                  id="outlined-textarea"
                  label="New entry"
                  style={{
                    width: "calc(100% - 130px)",
                    height: "100%",
                    display: "inline-grid",
                  }}
                  placeholder=""
                  inputRef={this.editTextBoxref}
                  onChange={this.handleOnChange}
                  multiline
                  variant="outlined"
                  name="execInput"
                />
                <label
                  htmlFor="contained-button-file"
                  style={{ width: "50px", display: "contents" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ backgroundColor: "darkgrey", height: "100%" }}
                    id="execAttachmentIcon"
                  >
                    <AttachFile />
                  </Button>
                </label>

                <Button
                  aria-label="split"
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={this.AddItem}
                  style={{ width: "50px", height: "100%" }}
                  id="execSendIcon"
                >
                  <Send />
                </Button>
                <input
                  data-fileindex={this.state.editedAttachments.length}
                  accept="*/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={this.appendFile}
                />
              </Container>
              <div style={{ paddingTop: "10px" }}>
                {this.state.editedAttachments.map((item: any) => {
                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "20px", margin: "10px" }}
                      endIcon={
                        <Delete
                          style={{ color: "red" }}
                          onClick={() => {
                            this.deletefile(item.id, item.name);
                          }}
                        />
                      }
                    >
                      {item.name}
                    </Button>
                  );
                })}
              </div>
            </Container>
          }
        </Container>
        <div
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            this.newEntry = el;
          }}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  activeHotel: state.hotelData.activeHotel,
});
export default connect(mapStateToProps)(withRouter(ExecSummary));
