import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { Component } from "react";
import "./onboardHotel.scss";
import SectionTitle from "../elements/sectionTitle";
import { toast } from "react-toastify";
import { YieldApi } from "../../api/yield";
import { rtTheme } from "../onboard-hotel/defs";
import Popup from "../../reusable-components/popup/popup";
import { OtaApi } from "../../api/ota";
import TimezoneSelect from "react-timezone-select";
import {
  TextField,
  Grid,
  Tooltip,
  Button,
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { MuiThemeProvider } from "@material-ui/core";

interface State {
  startDate: string;
  inputData: any;
  showPopup: boolean;
  hotel: any;
  submitBtnStatus: boolean;
  buttonClickstatus: boolean;
  compsetHotelNameList: any;
  newHotelNameValue: any;
  hover: boolean;
}

const validationSchema = Yup.object().shape({
  ChannelManager: Yup.string().required("Mandatory field"),
  ContractType: Yup.string().required("Mandatory field"),
  CurrencyCode: Yup.string().required("Mandatory field"),
  DefaultCompetitiveSetDisplayed: Yup.string().required("Mandatory field"),
  DefaultPrice: Yup.number()
    .max(10000000, "Maximum Value Exceeded")
    .min(0, "Negative number not allowed")
    .required("Mandatory field"),
  HotelCode: Yup.string().required("Mandatory field"),
  HotelName: Yup.string()
    .matches(
      /^[a-zA-Z\s'&]+$/,
      "Only alphabets, spaces, and following special characters allowed: & '",
    )
    .required("Mandatory field"),
  InventoryComesDirectlyFromPMSToChannelManager: Yup.string().when(
    "ChannelManager",
    {
      is: (value: string) => value === "guestline",
      then: (schema) => schema.required("Mandatory field"),
    },
  ),
  OWSDifferential: Yup.number()
    .max(10000, "Maximum Value Exceeded")
    .min(0, "Negative number not allowed")
    .required("Mandatory field"),
  PMSInventoryType: Yup.string().required("Mandatory field"),
  PMSProvider: Yup.string().required("Mandatory field"),
  PMSHotelCode: Yup.string().when("PMSProvider", {
    is: (value: string) => value !== "none",
    then: (schema) => schema.required("Mandatory field"),
  }),
  PMSProviderKey: Yup.string().when("PMSProvider", {
    is: (value: string) => value !== "none",
    then: (schema) => schema.required("Mandatory field"),
  }),
  PMSUrlEndpoint: Yup.string().when("PMSProvider", {
    is: (value: string) => value !== "none",
    then: (schema) => schema.required("Mandatory field"),
  }),
  Password: Yup.string().when("ChannelManager", {
    is: (value: string) => value !== "guestline",
    then: (schema) => schema.required("Mandatory field"),
  }),
  ShortName: Yup.string()
    .matches(
      /^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/,
      "Only alphabets and spaces allowed",
    )
    .required("Mandatory field"),
  TotalNumberOfRooms: Yup.number()
    .max(10000, "Maximum Value Exceeded")
    .min(0, "Negative number not allowed")
    .required("Mandatory field"),
  Username: Yup.string().when("ChannelManager", {
    is: (value: string) => value !== "guestline",
    then: (schema) => schema.required("Mandatory field"),
  }),

  UsingRewards: Yup.boolean().required("Mandatory field"),
  isRateMatrix: Yup.boolean().required("Mandatory field"),
  OTAHotelId: Yup.string().required("Mandatory field"),
});

export default class OnboardHotel extends Component {
  readonly state: State = {
    inputData: {
      InventoryComesDirectlyFromPMSToChannelManager: "",
      PMSProvider: "none",
      CurrencyCode: "EUR",
      timezone: "Europe/Dublin",
      ChannelManager: "",
    },
    startDate: moment().format("YYYY-MM-DD"),
    compsetHotelNameList: [],
    newHotelNameValue: {},
    showPopup: false,
    hotel: {},
    submitBtnStatus: false,
    buttonClickstatus: false,
    hover: false,
  };

  async componentDidMount() {
    const compsetHotelNameList = await OtaApi.getOtaHotelCompleteList();
    this.setState({
      compsetHotelNameList: compsetHotelNameList,
    });
  }

  cancelButtonClick = () => {
    this.setState({ buttonClickstatus: true });
  };

  handleOnMouseOver = () => {
    this.setState({ hover: true });
  };
  handleOnMouseOut = () => {
    this.setState({ hover: false });
  };

  render() {
    if (this.state.buttonClickstatus) {
      window.location.href = "/";
    }
    return (
      <MuiThemeProvider theme={rtTheme}>
        <div className="content">
          <h1 className="h">Onboard Hotel</h1>
          <Formik
            initialValues={{
              ChannelManager: "",
              ContractType: "",
              CurrencyCode: this.state.inputData.CurrencyCode,
              timezone: this.state.inputData.timezone,
              DefaultCompetitiveSetDisplayed: "",
              DefaultPrice: "",
              HotelCode: "",
              HotelName: "",
              InventoryComesDirectlyFromPMSToChannelManager: "",
              OWSDifferential: "",
              PMSInventoryType: "",
              PMSProvider: this.state.inputData.PMSProvider,
              Password: "",
              ShortName: "",
              TotalNumberOfRooms: "",
              Username: "",
              UsingRewards: "",
              isRateMatrix: "",
              OTAHotelId: "",
              PMSHotelCode: "",
              PMSProviderKey: "",
              PMSUrlEndpoint: "",
            }}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              this.setState({
                submitBtnStatus: true,
              });

              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

              this.setState(
                {
                  hotel: {
                    meta: {
                      defaultBar: values.DefaultPrice,
                      hasRewards: values.UsingRewards === "true" ? true : false,
                      globalRestriction: {
                        value: 6,
                        key: "max_days",
                      },
                      isPublishingInventory:
                        this.state.inputData.ChannelManager === "guestline"
                          ? false
                          : values.InventoryComesDirectlyFromPMSToChannelManager,
                      totalRooms: values.TotalNumberOfRooms,
                    },
                    info: {
                      level: values.ContractType,
                      contactEmails: [],
                      name: values.ShortName,
                      fullName: values.HotelName,
                      currencyCode: values.CurrencyCode,
                      timezone: values.timezone,
                    },
                    hotelOnboardDate: this.state.startDate,
                    pms: {
                      id: values.PMSProvider,
                      inventoryType: values.PMSInventoryType,
                      apiSettings: {
                        hotelCode: values.PMSHotelCode,
                        providerKey: values.PMSProviderKey,
                      },
                    },
                    cm: {
                      APISettings: {
                        providerId: "REVANISTA",
                        hotelId: values.HotelCode,
                      },
                      id: this.state.inputData.ChannelManager,
                      credentials: {
                        userId: values.Username ? values.Username : "",
                        password: values.Password,
                      },
                      channelConfig: {
                        ows: {
                          formula: "BR-" + values.OWSDifferential,
                          SeasonnalConfig: {
                            formulas: [],
                          },
                        },
                      },
                    },
                    ota: {
                      hotelId: this.state.newHotelNameValue["id"],
                      subscriptionId:
                        this.state.newHotelNameValue["subscription_id"],
                      defaultCompsetType: values.DefaultCompetitiveSetDisplayed,
                    },
                    suggestionParams: {
                      matrixes: [
                        {
                          bands: [],
                        },
                      ],
                      isEnabled: values.isRateMatrix,
                    },
                    ratePlans: {},
                    roomTypes: {},
                  },
                },
                () => {
                  const onboardPromise = toast.promise(
                    YieldApi.addNewHotel({
                      hotel: this.state.hotel,
                      start: this.state.startDate,
                    }),
                    {
                      pending: "Onboarding hotel...",
                      success: "Hotel onboarded successfully!",
                      error: "Failed to onboard hotel",
                    },
                  );

                  onboardPromise
                    .then(() => {
                      this.setState({
                        showPopup: true,
                        submitBtnStatus: false,
                      });
                      document.body.style.overflow = "auto";
                    })
                    .catch(() => {
                      this.setState({
                        submitBtnStatus: false,
                      });
                      document.body.style.overflow = "auto";
                    });
                },
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              setErrors,
              setFieldError,
            }) => {
              const handleNumericInput = (e: any) => {
                const { name, value } = e.target;
                setFieldValue(name, value.toString());
                if (value > 10000) {
                  setErrors({ [name]: "Maximum value exceeded" });
                  setFieldValue(name, value.toString());
                  setFieldTouched(name, true, true);
                } else if (value < 0) {
                  setErrors({ [name]: "Negative number not allowed" });
                  setFieldValue(name, value.toString());
                  setFieldTouched(name, true, true);
                } else {
                  setFieldTouched(name, false, false);
                  setFieldValue(name, value.toString());
                }
              };

              const handleTimezoneDrowpdown = (e: any) => {
                const name = "timezone";
                const value = e.value;
                if (value) {
                  setFieldValue(name, value);
                  setFieldTouched(name, false, false);
                } else {
                  setFieldValue(name, value);
                  setFieldError(name, "Mandatory Field");
                  setFieldTouched(name, true, true);
                }
              };

              const handleChangeHotelName = (e: any) => {
                const { name, value } = e.target;
                const pattern = /^[A-Za-z _&']*$/;
                setFieldValue(name, value);

                if (!value) {
                  setFieldError(name, "Mandatory Field");
                  setFieldTouched(name, true, true);
                } else if (!pattern.test(value)) {
                  setFieldError(
                    name,
                    "Only alphabets, spaces, and the following special characters are allowed: & '",
                  );
                  setFieldTouched(name, true, true);
                } else {
                  setFieldError(name, "");
                  setFieldTouched(name, false, false);
                }
              };

              const customHandleChange = (e: any) => {
                const { name, value } = e.target;
                if (name !== "") {
                  setFieldTouched(name, false, false);
                }
                if (name === "ChannelManager") {
                  if (value === "guestline") {
                    setFieldValue("PMSProvider", "guestline");
                    setFieldValue("ChannelManager", "guestline");
                    setFieldValue("PMSInventoryType", "live");
                    setFieldValue(
                      "InventoryComesDirectlyFromPMSToChannelManager",
                      "true",
                    );
                    this.setState({
                      inputData: {
                        ...this.state.inputData,
                        ["PMSProvider"]: "guestline",
                        ["ChannelManager"]: "guestline",
                        ["PMSInventoryType"]: "live",
                      },
                    });
                  } else {
                    setFieldValue("PMSProvider", "none");
                    setFieldValue("ChannelManager", value);
                    setFieldValue("PMSInventoryType", "manual");
                    this.setState({
                      inputData: {
                        ...this.state.inputData,
                        ["PMSProvider"]: "none",
                        ["ChannelManager"]: value,
                        ["PMSInventoryType"]: "manual",
                      },
                    });
                  }
                }
              };
              return (
                <form onSubmit={handleSubmit}>
                  <div className="main-div">
                    <SectionTitle {...{ value: "Hotel Settings" }} />
                    <br />
                    <div style={{ flexGrow: "1" }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            onChange={(e) => handleChangeHotelName(e)}
                            style={{ width: "100%" }}
                            value={values.HotelName}
                            fullWidth
                            id="outlined-basic"
                            label="Hotel Name"
                            variant="outlined"
                            name="Hotel Name"
                            helperText={
                              touched.HotelName && errors.HotelName
                                ? errors.HotelName
                                : ""
                            }
                            error={
                              touched.HotelName && Boolean(errors.HotelName)
                            }
                            inputProps={{ maxLength: 40 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            style={{ width: "100%" }}
                            fullWidth
                            id="outlined-basic"
                            label="Hotel Short Name"
                            variant="outlined"
                            name="ShortName"
                            onChange={(e) => handleChangeHotelName(e)}
                            error={Boolean(
                              touched.ShortName && errors.ShortName,
                            )}
                            helperText={touched.ShortName && errors.ShortName}
                            inputProps={{ maxLength: 20 }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl>
                            <InputLabel>Contract Type</InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              fullWidth
                              label="Contract Type"
                              variant="outlined"
                              name="ContractType"
                              value={values.ContractType}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.ContractType && errors.ContractType,
                              )}
                            >
                              <MenuItem value="Pro">Pro</MenuItem>
                              <MenuItem value="Plus">Plus</MenuItem>
                              <MenuItem value="Assist">Assist</MenuItem>
                            </Select>
                            <FormHelperText>
                              {touched.ContractType && errors.ContractType}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl>
                            <InputLabel>Rewards Site Function</InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              defaultValue=""
                              fullWidth
                              onChange={handleChange}
                              label="Rewards Site Function"
                              variant="outlined"
                              name="UsingRewards"
                              value={values.UsingRewards}
                              error={Boolean(
                                touched.UsingRewards && errors.UsingRewards,
                              )}
                            >
                              <MenuItem value="true">Enabled</MenuItem>
                              <MenuItem value="false">Disabled</MenuItem>
                            </Select>
                            <FormHelperText>
                              {touched.UsingRewards && errors.UsingRewards}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl>
                            <InputLabel>
                              Default Competitive Set Displayed
                            </InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              fullWidth
                              onChange={handleChange}
                              value={values.DefaultCompetitiveSetDisplayed}
                              label="Default Competitive Set Displayed"
                              variant="outlined"
                              name="DefaultCompetitiveSetDisplayed"
                              error={Boolean(
                                touched.DefaultCompetitiveSetDisplayed &&
                                  errors.DefaultCompetitiveSetDisplayed,
                              )}
                            >
                              <MenuItem value="oneGuestRO">1 Guest RO</MenuItem>
                              <MenuItem value="oneGuestBB">1 Guest BB</MenuItem>
                              <MenuItem value="RO">2 Guest RO</MenuItem>
                              <MenuItem value="BB">2 Guest BB</MenuItem>
                            </Select>
                            <FormHelperText>
                              {touched.DefaultCompetitiveSetDisplayed &&
                                errors.DefaultCompetitiveSetDisplayed}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            style={{ width: "100%" }}
                            type="number"
                            fullWidth
                            id="outlined-basic"
                            label="Total Number of Rooms"
                            value={values.TotalNumberOfRooms}
                            variant="outlined"
                            onChange={(e) => handleNumericInput(e)}
                            name="TotalNumberOfRooms"
                            inputProps={{ min: 0 }}
                            error={Boolean(
                              touched.TotalNumberOfRooms &&
                                errors.TotalNumberOfRooms,
                            )}
                            helperText={
                              touched.TotalNumberOfRooms &&
                              errors.TotalNumberOfRooms
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            style={{ width: "100%" }}
                            type="number"
                            fullWidth
                            id="outlined-basic"
                            label="Default Base Rate"
                            variant="outlined"
                            onChange={(e) => handleNumericInput(e)}
                            value={values.DefaultPrice}
                            name="DefaultPrice"
                            inputProps={{ min: 0 }}
                            error={Boolean(
                              touched.DefaultPrice && errors.DefaultPrice,
                            )}
                            helperText={
                              touched.DefaultPrice && errors.DefaultPrice
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            style={{ width: "100%" }}
                            type="number"
                            fullWidth
                            id="outlined-basic"
                            label="OWS Differential"
                            variant="outlined"
                            name="OWSDifferential"
                            onChange={(e) => handleNumericInput(e)}
                            value={values.OWSDifferential}
                            inputProps={{ min: 0 }}
                            error={Boolean(
                              touched.OWSDifferential && errors.OWSDifferential,
                            )}
                            helperText={
                              touched.OWSDifferential && errors.OWSDifferential
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl>
                            <InputLabel>Rate Matrix</InputLabel>
                            <Select
                              defaultValue=""
                              style={{ width: "100%" }}
                              fullWidth
                              label="Rate Matrix"
                              variant="outlined"
                              onChange={handleChange}
                              name="isRateMatrix"
                              value={values.isRateMatrix}
                              error={Boolean(
                                touched.isRateMatrix && errors.isRateMatrix,
                              )}
                            >
                              <MenuItem value="true">Enabled</MenuItem>
                              <MenuItem value="false">Disabled</MenuItem>
                            </Select>
                            <FormHelperText>
                              {touched.isRateMatrix && errors.isRateMatrix}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl>
                            <InputLabel>Currency</InputLabel>
                            <Select
                              defaultValue={this.state.inputData.CurrencyCode}
                              style={{ width: "100%" }}
                              fullWidth
                              label="Currency"
                              onChange={handleChange}
                              value={values.CurrencyCode}
                              variant="outlined"
                              name="CurrencyCode"
                              error={Boolean(errors.CurrencyCode)}
                            >
                              <MenuItem value="EUR">EUR</MenuItem>
                              <MenuItem value="GBP">GBP</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TimezoneSelect
                            className="timeZone"
                            name="Timezone"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                minHeight: "56px",
                                borderRadius: "4px",
                                borderColor: "#c4c4c4",
                                "&:hover": {
                                  borderColor: "#000",
                                },
                                boxShadow: "none",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#666666",
                              }),
                              input: (provided) => ({
                                ...provided,
                                margin: "0px",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "8px 14px",
                              }),
                            }}
                            value={values.timezone}
                            onChange={(e) => handleTimezoneDrowpdown(e)}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <br />
                    <SectionTitle {...{ value: "Channel Manager Settings" }} />
                    <br />
                    <div style={{ flexGrow: "1" }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl>
                            <InputLabel>CM Provider</InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              fullWidth
                              label="Channel Manager"
                              onChange={(e) => customHandleChange(e)}
                              variant="outlined"
                              name="ChannelManager"
                              error={Boolean(
                                touched.ChannelManager && errors.ChannelManager,
                              )}
                            >
                              <MenuItem value="siteMinder">SiteMinder</MenuItem>
                              <MenuItem value="channelRush">
                                Channel Rush
                              </MenuItem>
                              <MenuItem value="guestline">Guestline</MenuItem>
                            </Select>
                            <FormHelperText>
                              {touched.ChannelManager && errors.ChannelManager}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}></Grid>
                        <Grid item xs={12} sm={6} md={4}></Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            style={{ width: "100%" }}
                            fullWidth
                            id="outlined-basic"
                            label="Hotel Code"
                            variant="outlined"
                            onChange={handleChange}
                            value={values.HotelCode}
                            name="HotelCode"
                            inputProps={{ maxLength: 20 }}
                            error={
                              touched.HotelCode && Boolean(errors.HotelCode)
                            }
                            helperText={touched.HotelCode && errors.HotelCode}
                          />
                        </Grid>

                        {values.ChannelManager !== "guestline" && (
                          <>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                style={{ width: "100%" }}
                                fullWidth
                                id="outlined-basic"
                                label="Username"
                                variant="outlined"
                                onChange={handleChange}
                                value={values.Username}
                                name="Username"
                                inputProps={{ maxLength: 20 }}
                                error={
                                  touched.Username && Boolean(errors.Username)
                                }
                                helperText={touched.Username && errors.Username}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                style={{ width: "100%" }}
                                fullWidth
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                                onChange={handleChange}
                                value={values.Password}
                                name="Password"
                                error={
                                  touched.Password && Boolean(errors.Password)
                                }
                                helperText={touched.Password && errors.Password}
                                inputProps={{ maxLength: 20 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <Tooltip
                                title="If enabled, Revanista will allow to publish availability to Channel Manager. If disabled, property's PMS should be sending availability to Channel Manager."
                                arrow
                                placement="left"
                              >
                                <FormControl>
                                  <InputLabel>Publish Inventory</InputLabel>
                                  <Select
                                    defaultValue=""
                                    fullWidth
                                    label="Publish Inventory"
                                    variant="outlined"
                                    onChange={handleChange}
                                    name="InventoryComesDirectlyFromPMSToChannelManager"
                                    value={
                                      values.InventoryComesDirectlyFromPMSToChannelManager
                                    }
                                    error={Boolean(
                                      touched.InventoryComesDirectlyFromPMSToChannelManager &&
                                        errors.InventoryComesDirectlyFromPMSToChannelManager,
                                    )}
                                  >
                                    <MenuItem value="true">Enabled</MenuItem>
                                    <MenuItem value="false">Disabled</MenuItem>
                                  </Select>
                                  <FormHelperText>
                                    {touched.InventoryComesDirectlyFromPMSToChannelManager &&
                                      errors.InventoryComesDirectlyFromPMSToChannelManager}
                                  </FormHelperText>
                                </FormControl>
                              </Tooltip>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </div>
                    <br />
                    <SectionTitle {...{ value: "PMS Settings" }} />
                    <br />
                    <div style={{ flexGrow: "1" }}>
                      <Grid container spacing={3}>
                        {values.ChannelManager === "guestline" ? (
                          <>
                            <Grid item xs={12} sm={6} md={4}>
                              <FormControl>
                                <InputLabel>PMS Provider</InputLabel>
                                <Select
                                  value={values.PMSProvider}
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#D3D3D3",
                                  }}
                                  fullWidth
                                  label="PMS Provider"
                                  variant="outlined"
                                  name="PMSProvider"
                                  onChange={handleChange}
                                  inputProps={{ readOnly: true }}
                                >
                                  <MenuItem value="guestline">
                                    Guestline
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} sm={6} md={4}>
                              <FormControl>
                                <InputLabel>PMS Provider</InputLabel>
                                <Select
                                  value={values.PMSProvider}
                                  style={{
                                    width: "100%",
                                  }}
                                  fullWidth
                                  label="PMS Provider"
                                  variant="outlined"
                                  name="PMSProvider"
                                  onChange={handleChange}
                                >
                                  <MenuItem value="hotsoft">HotSoft</MenuItem>
                                  <MenuItem value="none">None</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} sm={6} md={4}></Grid>
                        <Grid item xs={12} sm={6} md={4}></Grid>
                        {values.PMSProvider === "hotsoft" && (
                          <>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                style={{ width: "100%" }}
                                fullWidth
                                id="outlined-basic"
                                label="Hotel Code"
                                variant="outlined"
                                name="PMSHotelCode"
                                onChange={handleChange}
                                inputProps={{ maxLength: 20 }}
                                error={
                                  touched.PMSHotelCode &&
                                  Boolean(errors.PMSHotelCode)
                                }
                                helperText={
                                  touched.PMSHotelCode && errors.PMSHotelCode
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                style={{ width: "100%" }}
                                fullWidth
                                id="outlined-basic"
                                label="Provider Key"
                                variant="outlined"
                                name="PMSProviderKey"
                                onChange={handleChange}
                                inputProps={{ maxLength: 20 }}
                                error={
                                  touched.PMSProviderKey &&
                                  Boolean(errors.PMSProviderKey)
                                }
                                helperText={
                                  touched.PMSProviderKey &&
                                  errors.PMSProviderKey
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                style={{ width: "100%" }}
                                fullWidth
                                id="outlined-basic"
                                label="URL Endpoint"
                                variant="outlined"
                                name="PMSUrlEndpoint"
                                onChange={handleChange}
                                inputProps={{ maxLength: 20 }}
                                error={
                                  touched.PMSUrlEndpoint &&
                                  Boolean(errors.PMSUrlEndpoint)
                                }
                                helperText={
                                  touched.PMSUrlEndpoint &&
                                  errors.PMSUrlEndpoint
                                }
                              />
                            </Grid>
                          </>
                        )}
                        {values.PMSProvider !== "none" && (
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl>
                              <InputLabel>Inventory Type</InputLabel>
                              <Select
                                value={values.PMSInventoryType}
                                style={{
                                  width: "100%",
                                  backgroundColor:
                                    values.ChannelManager === "guestline"
                                      ? "#D3D3D3"
                                      : "none",
                                }}
                                fullWidth
                                label="Inventory Type"
                                variant="outlined"
                                name="PMSInventoryType"
                                onChange={handleChange}
                                error={
                                  touched.PMSInventoryType &&
                                  Boolean(errors.PMSInventoryType)
                                }
                                inputProps={{
                                  readOnly:
                                    values.ChannelManager === "guestline",
                                }}
                              >
                                <MenuItem value="manual">Manual</MenuItem>
                                <MenuItem value="live">Live</MenuItem>
                              </Select>
                              <FormHelperText>
                                {touched.PMSInventoryType &&
                                  errors.PMSInventoryType}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                    <br />
                    <SectionTitle {...{ value: "OTA Insight Settings" }} />
                    <br />
                    <div style={{ flexGrow: "1" }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Autocomplete
                            options={this.state.compsetHotelNameList}
                            getOptionLabel={(option: any) => option.name}
                            id="outlined-basic"
                            value={
                              this.state.compsetHotelNameList.find(
                                (option: any) =>
                                  option.name === values.OTAHotelId,
                              ) || null
                            }
                            onChange={(event, newHotelNameValue) => {
                              this.setState({
                                newHotelNameValue: newHotelNameValue,
                              });
                              setFieldValue(
                                "OTAHotelId",
                                newHotelNameValue ? newHotelNameValue.name : "",
                              );
                              setFieldTouched("OTAHotelId", true);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select OTA Hotel Name"
                                style={{ width: "100%" }}
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                name="OTAHotelId"
                                value={values.OTAHotelId}
                                error={Boolean(
                                  touched.OTAHotelId && errors.OTAHotelId,
                                )}
                                helperText={
                                  touched.OTAHotelId && errors.OTAHotelId
                                }
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                  <div
                    style={{
                      float: "right",
                      marginRight: "2vh",
                      marginBottom: "2vh",
                    }}
                  >
                    <Button
                      onClick={this.cancelButtonClick}
                      color="secondary"
                      variant="contained"
                      style={{
                        backgroundColor: "#808080",
                        color: "#ffffff",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={this.state.submitBtnStatus}
                      color="primary"
                      variant="contained"
                      style={{
                        backgroundColor: "#ce0e29",
                        color: "#ffffff",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      {this.state.submitBtnStatus
                        ? "Creating..."
                        : "Create Hotel"}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
          {this.state.showPopup && (
            <Popup
              isPopupOpen={this.state.showPopup}
              title={`${this.state.hotel.info.name} has been created`}
              content={[
                <p key="popup-content">
                  Please proceed with creating room types & rate plans through
                  the settings page.
                </p>,
              ]}
              action={() => {
                this.setState({
                  displayWarning: false,
                  buttonClickstatus: true,
                });
              }}
              actionName="Back to Dashboard"
            />
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}
