import React, { Component } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

const theme: Theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: "12px",
      },
      root: {
        background: "white",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-block",
        lineHeight: "50px",
      },
    },
  },
});

interface State {
  value: string;
}

interface Props {
  value: string;
}

class SectionTitle extends Component {
  readonly state: State = {
    value: "",
  };

  constructor(props: Props) {
    super(props);
    this.state.value = props.value;
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ backgroundColor: "#cc3f53", width: "100%", height: 50 }}>
          <span
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "white",
              margin: 30,
              lineHeight: "50px",
            }}
          >
            {this.state.value}
          </span>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default SectionTitle;
