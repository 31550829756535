import { createTheme } from "@material-ui/core";

const rtTheme = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },
    MuiCard: {
      root: {
        width: "calc(33% - 8px)",
        float: "left",
        marginLeft: "10px",
        marginTop: "10px",
        fontSize: "1em",
      },
    },
    MuiCardContent: {
      root: {
        fontSize: "1em",
      },
    },
    MuiTypography: {
      root: {
        float: "left",
        width: "100%!important",
      },
    },
    MuiTextField: {
      root: {
        display: "inline-block",
        width: 230,
      },
    },
    MuiSvgIcon: {
      root: {
        float: "right",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-bock",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#fce8e6",
          color: "#d93025",
          fontWeight: "bolder",
        },
      },
    },
  },
});

const rpTheme = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },
    MuiCard: {
      root: {
        height: 50,
        width: "calc(25% - 8px)",
        float: "left",
        marginLeft: "8px",
        marginTop: "8px",
        fontSize: "1em",
      },
    },
    MuiCardContent: {
      root: {
        fontSize: "1em",
        padding: 0,
        paddingLeft: 15,
        lineHeight: "50px",
      },
    },
    MuiTypography: {
      root: {
        lineHeight: "50px!important",
        width: "calc(100% - 90px)!important",
        float: "left",
      },
    },
    MuiTextField: {
      root: {
        display: "inline-block",
      },
    },
    MuiSvgIcon: {
      root: {
        float: "right",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-bock",
      },
    },
    // MuiListItem: {
    //   root: {
    //     "&$selected": {
    //       backgroundColor: "#fce8e6",
    //       color: "#d93025",
    //       fontWeight: "bolder",
    //     },
    //   },
    // },
    MuiIconButton: {
      root: {
        padding: 2,
        marginRight: 0,
      },
    },
  },
});

export { rpTheme, rtTheme };
