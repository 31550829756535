import React, { Component, Fragment, RefObject, createRef } from "react";
import { hotel as gnHotel } from "gn-shared";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SectionTitle from "../../elements/sectionTitle";
import { CSSTransition } from "react-transition-group";
import EditRatePlanPanel from "./editRatePlanPanel";
import EditRoomPanel from "./editRoomPanel";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import { MuiThemeProvider } from "@material-ui/core";
import {
  Container,
  Button,
  Divider,
  CardActions,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Chip,
  Checkbox,
} from "@material-ui/core";
import { State, rtTheme, MenuProps } from "./defs";
import { toast } from "react-toastify";
import { YieldSheetService } from "../../services";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import "./roomStyle.css";
import { appStore } from "../../store";
import withRouter from "../../withRouter";
import Popup from "../../../reusable-components/popup/popup";
import { connect } from "react-redux";
import { setActiveHotel } from "redux/Slice/hotelSlice";
import { YieldApi } from "api/yield";
const emptyRp = {
  modeOfSale: "",
  id: "",
  longDescription: "",
  shortDescription: "",
  searchModeOfSale: "",
  applies: [],
};

const emptyRT = {
  shortDescription: "",
  id: "",
  minRate: "",
  maxRate: "",
  occupancy: "",
  childOccupancy: "",
  inventory: "",
  formula: "",
  sortOrder: 0,
};

class RoomConfig extends Component<{
  activeHotel: any;
  router: {
    location: {
      pathname: string;
      search: string;
      state: State;
      hash: string;
    };
    navigate: (path: string) => void;
  };
  setActiveHotel: (hotel: any) => void;
}> {
  private mainDivRefs: RefObject<HTMLDivElement>[] = [];
  private mainDivRefsRT: RefObject<HTMLDivElement>[] = [];
  readonly state: State = {
    roomData: JSON.parse(JSON.stringify(emptyRT)),
    isRTPanelOpened: false,
    isRTPanelNew: true,
    rateplanData: JSON.parse(JSON.stringify(emptyRp)),
    isRPPanelOpened: false,
    isRPPanelNew: false,
    isDeleteRpDialogOpened: false,
    isDeleteRtDialogOpened: false,
    rpToDelete: {},
    rtToDelete: {},
    unEditable: false,
    unEditableRP: false,
    isOverflow: [],
    isOverflowRT: [],
    ratePlanArray: [],
    roomTypeData: [],
    counter: 0,
    searchInput: "",
    searchDropDown: [],
    isDraging: true,
    rtUpdatedStatus: true,
    rpUpdatedStatus: true,
    rtUpdateData: {},
    previousLocation: "",
    key: 0,
    setRateFilter: "All",
    searchModeOfSale: "All",
  };
  currentUser: string;
  isAdmin: boolean;
  hotel: any;
  navigate: any;

  constructor(props: any) {
    super(props);

    this.currentUser = appStore.meta._user.username;
    this.isAdmin =
      appStore.meta._user.permissions.admin[0] === "*" ? true : false;
  }

  initializeHotelData() {
    if (!this.hotel) return;

    const rtArray = this.hotel
      .getRoomTypes()
      .sort((a: { sortOrder: number }, b: { sortOrder: number }) => {
        return a.sortOrder - b.sortOrder;
      });

    const rtModArray: {
      sortOrder: number;
      shortDescription: string;
      deleted?: boolean;
    }[] = [];
    rtArray.forEach(
      (
        rt: { sortOrder: number; shortDescription: string; deleted?: boolean },
        index: number,
      ) => {
        if (typeof rt.deleted == "undefined") {
          rtModArray.push(rtArray[index]);
        }
      },
    );

    const rpsShortDescription = this.hotel
      .getRatePlans()
      .sort((a: { sortOrder: number }, b: { sortOrder: number }) => {
        return a.sortOrder - b.sortOrder;
      });

    const rpsModShortDescription: {
      sortOrder: number;
      shortDescription: string;
      deleted?: boolean;
    }[] = [];
    rpsShortDescription.forEach(
      (
        rp: { sortOrder: number; shortDescription: string; deleted?: boolean },
        index: number,
      ) => {
        if (typeof rp.deleted == "undefined") {
          rpsModShortDescription.push(rpsShortDescription[index]);
        }
      },
    );

    // Create new refs arrays
    const rts = rtModArray.map(
      (rt: { shortDescription: string }) => rt.shortDescription,
    );
    const rps = rpsModShortDescription.map(
      (data: { shortDescription: string }) => data.shortDescription,
    );

    this.mainDivRefs = rps.map(() => createRef<HTMLDivElement>());
    this.mainDivRefsRT = rts.map(() => createRef<HTMLDivElement>());

    // Update state with new data
    this.setState({
      roomTypeData: rtModArray,
      ratePlanArray: rpsModShortDescription,
      isOverflow: rps.map(() => false),
      isOverflowRT: rts.map(() => false),
    });
  }

  async componentDidMount() {
    this.checkOverflow();
    window.addEventListener("resize", this.checkOverflow);

    if (
      this.props.activeHotel &&
      Object.keys(this.props.activeHotel).length > 0
    ) {
      const hotelObject = JSON.parse(JSON.stringify(this.props.activeHotel));
      this.hotel = gnHotel(hotelObject);
      this.initializeHotelData();
    }
  }

  async componentDidUpdate(prevProps: {
    activeHotel: typeof emptyRp | typeof emptyRT;
  }) {
    // Check if activeHotel has changed and is not empty
    if (
      prevProps.activeHotel !== this.props.activeHotel &&
      this.props.activeHotel &&
      Object.keys(this.props.activeHotel).length > 0
    ) {
      const hotelObject = JSON.parse(JSON.stringify(this.props.activeHotel));
      this.hotel = gnHotel(hotelObject);
      this.initializeHotelData();
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.checkOverflow);
  }

  onHotelUpdate = async () => {
    const response = await YieldApi.getHotelConfig(
      this.props.activeHotel.hotelId,
    );
    console.log("hotel", response);
    this.props.setActiveHotel(response);
  };

  closeRPPanel = () => {
    this.setState({ isRPPanelOpened: false });
  };
  closeRoomPanel = () => {
    this.setState({ isRTPanelOpened: false });
  };

  updateRPinStore = () => {
    this.onHotelUpdate();
  };

  updateRoomTypeinStore = () => {
    this.onHotelUpdate();
  };

  deleteRateplan = async (rp: { id: string; shortDescription: string }) => {
    const hotel = this.hotel;
    this.setState({ isDeleteRpDialogOpened: false });

    const toastDeleteRP = toast.promise(
      YieldSheetService.revanista.hotel.deleteRateplan(rp.id, hotel.hotelId),
      {
        pending:
          "Deleting RatePlan - '" + rp.shortDescription + "' in progress...",
        error: "Error deleting Rate Plan",
        success: "Rate Plan deleted",
      },
    );

    toastDeleteRP.then(() => {
      delete hotel.ratePlans[rp.id];
      this.onHotelUpdate();
      this.setState({ hotel });
    });
  };

  deleteRoomType = async (rt: { id: string; shortDescription: string }) => {
    const hotel = this.hotel;
    this.setState({ isDeleteRtDialogOpened: false });

    const toastDeleteRT = toast.promise(
      YieldSheetService.revanista.hotel.deleteRoomType(rt.id, hotel.hotelId),
      {
        pending:
          "Deleting Room Type - '" + rt.shortDescription + "' in progress...",
        error: "Error deleting Room Type",
        success: "Room Type deleted",
      },
    );

    toastDeleteRT.then(() => {
      delete hotel.roomTypes[rt.id];
      this.onHotelUpdate();
      this.setState({ hotel });
    });
  };

  openDeleteDialog = (rp: { id: string; shortDescription: string }) => {
    this.setState({
      rpToDelete: JSON.parse(JSON.stringify(rp)),
      isDeleteRpDialogOpened: true,
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      isDeleteRpDialogOpened: false,
    });
  };

  openDeleteRtDialog = (rt: { id: string; shortDescription: string }) => {
    this.setState({
      rtToDelete: JSON.parse(JSON.stringify(rt)),
      isDeleteRtDialogOpened: true,
    });
  };

  closeDeleteRtDialog = () => {
    this.setState({
      isDeleteRtDialogOpened: false,
    });
  };

  openEditRPPanel = (rpId: string | null) => {
    let rp;
    let isNew = false;
    if (!rpId) {
      rp = JSON.parse(JSON.stringify(emptyRp));
      isNew = true;
    } else {
      rp = JSON.parse(JSON.stringify(this.hotel.ratePlans[rpId]));
    }
    this.setState({ unEditableRP: true });
    this.setState({
      rateplanData: rp,
      isRPPanelOpened: true,
      isRPPanelNew: isNew,
    });
  };

  openEditRPPanelByPlus = (rpId: string | null) => {
    let rp;
    let isNew = false;
    if (!rpId) {
      rp = JSON.parse(JSON.stringify(emptyRp));
      isNew = true;
    } else {
      rp = JSON.parse(JSON.stringify(this.hotel.ratePlans[rpId]));
    }
    this.setState({ unEditableRP: false });
    this.setState({
      rateplanData: rp,
      isRPPanelOpened: true,
      isRPPanelNew: isNew,
    });
  };

  openEditRoomPanel = (rtID: string | null) => {
    let rt;
    let isNew = false;
    if (!rtID) {
      rt = JSON.parse(JSON.stringify(emptyRT));
      isNew = true;
    } else {
      rt = JSON.parse(JSON.stringify(this.hotel.roomTypes[rtID]));
    }
    this.setState({ unEditable: true });
    this.setState({
      roomData: rt,
      isRTPanelOpened: true,
      isRTPanelNew: isNew,
    });
  };

  openEditRoomPanelByPlus = (rtID: string | null) => {
    let rt;
    let isNew = false;
    if (!rtID) {
      rt = JSON.parse(JSON.stringify(emptyRT));
      isNew = true;
    } else {
      rt = JSON.parse(JSON.stringify(this.hotel.roomTypes[rtID]));
    }
    this.setState({ unEditable: false });
    this.setState({
      roomData: rt,
      isRTPanelOpened: true,
      isRTPanelNew: isNew,
    });
  };

  checkOverflow = () => {
    const updatedIsOverflow = this.mainDivRefs.map((ref) => {
      return (
        (ref.current && ref.current.scrollWidth > ref.current.clientWidth) ||
        (ref.current && ref.current.scrollHeight > ref.current.clientHeight)
      );
    });
    this.setState({ isOverflow: updatedIsOverflow });

    const updatedIsOverflowForRT = this.mainDivRefsRT.map((ref) => {
      return (
        (ref.current && ref.current.scrollWidth > ref.current.clientWidth) ||
        (ref.current && ref.current.scrollHeight > ref.current.clientHeight)
      );
    });
    this.setState({ isOverflowRT: updatedIsOverflowForRT });
  };

  getCardForRP = (
    RPOnly: { id: string; shortDescription: string; applies?: string[] },
    index: number,
  ) => {
    const desc = RPOnly.shortDescription;
    const isRoomType = !RPOnly.applies;
    const { isOverflow } = this.state;

    return (
      <Card key={RPOnly.id}>
        <div className="badge">{index + 1}</div>
        <CardActionArea>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              style={{ fontSize: "1em", width: "80%" }}
            >
              <div
                ref={this.mainDivRefs[index]}
                className={
                  isOverflow[index] ? "message_ticker_new" : "message_ticker"
                }
              >
                {desc}
              </div>
            </Typography>
            <Fragment>
              <Tooltip title="Open">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    !isRoomType
                      ? this.openEditRPPanel(RPOnly.id)
                      : this.openEditRoomPanel(RPOnly.id);
                    event.stopPropagation();
                  }}
                  style={{ margin: "7px" }}
                >
                  <OpenInNewIcon id={`open_${RPOnly.shortDescription}`} />
                </IconButton>
              </Tooltip>
              {this.isAdmin && (
                <Tooltip title="Remove">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => {
                      this.openDeleteDialog(
                        RPOnly as { id: string; shortDescription: string },
                      );
                    }}
                  >
                    <DeleteIcon
                      id={`delete_${RPOnly.id}`}
                      style={{ fill: "crimson" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Fragment>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  getCardForRT = (
    RTOnly: { id: string; shortDescription: string; applies?: string[] },
    index: number,
  ) => {
    const desc = RTOnly.shortDescription;
    const isRoomType = !RTOnly.applies;
    const { isOverflowRT } = this.state;
    console.log("RT", RTOnly);
    return (
      <Card key={RTOnly.id}>
        <div className="badge">{index + 1}</div>
        <CardActionArea>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              style={{ fontSize: "1em", width: "80%" }}
            >
              <div
                ref={this.mainDivRefsRT[index]}
                className={
                  isOverflowRT[index] ? "message_ticker_new" : "message_ticker"
                }
              >
                {desc}
              </div>
            </Typography>

            <Fragment>
              <Tooltip title="Open">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    this.state.isRTPanelOpened && !isRoomType
                      ? this.openEditRPPanel(RTOnly.id)
                      : this.openEditRoomPanel(RTOnly.id);
                    event.stopPropagation();
                  }}
                  style={{ margin: "7px" }}
                >
                  <OpenInNewIcon id={`open_${RTOnly.shortDescription}`} />
                </IconButton>
              </Tooltip>
              {this.isAdmin && (
                <Tooltip title="Remove">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    // component="span"
                    onClick={() => {
                      this.openDeleteRtDialog(
                        RTOnly as { id: string; shortDescription: string },
                      );
                    }}
                  >
                    <DeleteIcon
                      id={`delete_${RTOnly.shortDescription}`}
                      style={{ fill: "crimson" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Fragment>
          </CardContent>
        </CardActionArea>
        <CardActions></CardActions>
      </Card>
    );
  };

  onChange = async (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId?: string,
  ) => {
    this.setState({ counter: this.state.counter + 1 });
    if (sourceId === "roomType") {
      const sortedRt = this.hotel
        .getRoomTypes()
        .sort((a: { sortOrder: number }, b: { sortOrder: number }) => {
          return a.sortOrder - b.sortOrder;
        });
      const rt = sortedRt[sourceIndex];
      const rtId = sortedRt[sourceIndex].id;

      const nextState = swap(sortedRt, sourceIndex, targetIndex);

      this.updateRtSortOrder(
        rt,
        rtId,
        targetIndex,
        nextState as {
          id: string;
          sortOrder: number;
          shortDescription: string;
        }[],
      );
    } else if (sourceId === "ratePlan") {
      console.log(sourceId, sourceIndex, targetIndex, targetId);

      const sortedRp = this.hotel
        .getRatePlans()
        .sort((a: { sortOrder: number }, b: { sortOrder: number }) => {
          return a.sortOrder - b.sortOrder;
        });
      const rp = sortedRp[sourceIndex];
      const rpId = sortedRp[sourceIndex].id;

      const nextState = swap(sortedRp, sourceIndex, targetIndex);

      this.updateRpSortOrder(
        rp,
        rpId,
        targetIndex,
        nextState as {
          id: string;
          shortDescription: string;
          sortOrder: number;
        }[],
      );
    }
  };

  updateRtSortOrder = async (
    rt: string,
    rtId: number,
    targetIndex: number,
    nextState: { id: string; sortOrder: number; shortDescription: string }[],
  ) => {
    const hotel = this.hotel;
    hotel.roomTypes[rtId].sortOrder = targetIndex;
    this.setState({
      roomTypeData: nextState,
    });

    console.log("room type dta", this.state.roomTypeData);

    const dataObject: {
      roomType: {
        [key: string]: {
          id: string;
          sortOrder: number;
          shortDescription: string;
        };
      };
    } = { roomType: {} };

    nextState.forEach(
      (
        item: { id: string; sortOrder: number; shortDescription: string },
        index: number,
      ) => {
        item.sortOrder = index;
        dataObject.roomType[item.id] = item;
      },
    );

    try {
      this.setState({
        isRTPanelOpened: false,
        isDraging: false,
      });
      await YieldSheetService.revanista.hotel.writeRtSortOrder(
        dataObject,
        this.hotel.hotelId,
      );
      this.setState({ hotel });
      this.onHotelUpdate();
      this.setState({
        isRTPanelOpened: false,
        isDraging: false,
      });
    } catch (err) {
      console.log(err);
      toast.error("Error updating room type order");
    }
  };

  updateRpSortOrder = async (
    rp: { id: string; shortDescription: string; sortOrder: number },
    rpId: string,
    targetIndex: number,
    nextState: { id: string; shortDescription: string; sortOrder: number }[],
  ) => {
    const hotel = this.hotel;
    hotel.ratePlans[rpId].sortOrder = targetIndex;
    this.setState({
      ratePlanArray: nextState,
    });

    const dataObject: {
      ratePlan: {
        [key: string]: {
          id: string;
          shortDescription: string;
          sortOrder: number;
        };
      };
    } = { ratePlan: {} };

    nextState.forEach(
      (
        item: { id: string; shortDescription: string; sortOrder: number },
        index: number,
      ) => {
        item.sortOrder = index;
        dataObject.ratePlan[item.id] = item;
      },
    );

    try {
      await YieldSheetService.revanista.hotel.writeRpSortOrder(
        dataObject,
        this.hotel.hotelId,
      );
      this.setState({ hotel });
      this.onHotelUpdate();
    } catch (err) {
      console.log(err);
      toast.error("Error updating room type order");
    }
  };

  handleChange = (event: SelectChangeEvent<string | string[]>) => {
    const name = event.target.name;
    const value = event.target.value;

    const rtDetail = this.state.roomTypeData.map(
      (data: { id: string; shortDescription: string }) => {
        return { label: data.shortDescription, value: data.id };
      },
    );

    switch (name) {
      case "search":
        console.log("value", value);
        this.setState({
          searchInput: value,
        });
        break;

      case "roomType": {
        const { searchDropDown } = this.state;

        if ((value as string[])[(value as string[]).length - 1] === "all") {
          const options = rtDetail.map(
            (data: { label: string; value: string }) => data.value,
          );

          this.setState({
            searchDropDown:
              this.state.roomTypeData.length === searchDropDown.length
                ? []
                : options,
          });

          return;
        }
        this.setState({
          searchDropDown: [...(value as string[])],
        });
        break;
      }
      case "searchModeOfSale":
        this.setState({
          searchModeOfSale: value,
        });
        break;

      case "setRateFilter":
        this.setState({
          setRateFilter: value,
        });
        break;

      default:
        break;
    }
  };

  render() {
    console.log("activeHotel", this.props.activeHotel);
    const { searchInput, searchDropDown, searchModeOfSale, setRateFilter } =
      this.state;

    const rtDetail = this.state.roomTypeData.map(
      (data: { id: string; shortDescription: string }) => {
        return { label: data.shortDescription, value: data.id };
      },
    );

    const filteredData = this.state.ratePlanArray.filter(
      (item: {
        id: string;
        shortDescription: string;
        modeOfSale: string;
        applies: string[];
        defaultRate?: number;
        formula?: string;
      }) => {
        const matchesSearchInput =
          searchInput.length === 0 ||
          item.shortDescription
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          item.id.toLowerCase().includes(searchInput.toLowerCase());

        const matchesModeOfSale =
          (searchModeOfSale === "All" && item) ||
          item.modeOfSale === searchModeOfSale;

        const matchSetRate =
          (setRateFilter === "All" && item) ||
          (setRateFilter === "set rate" && item.defaultRate !== 0) ||
          (setRateFilter === "without set rate" &&
            item.formula &&
            item.formula.length !== 0);

        const matchesSearchDropDown =
          (searchDropDown.length === 0 && item) ||
          (searchDropDown.includes("all") && item) ||
          searchDropDown.every((element: string) =>
            item.applies.includes(element),
          );

        return (
          matchesSearchInput &&
          matchesModeOfSale &&
          matchSetRate &&
          matchesSearchDropDown
        );
      },
    );

    return (
      <MuiThemeProvider theme={rtTheme}>
        <GridContextProvider onChange={this.onChange}>
          <div className="content">
            {!this.isAdmin && (
              <p
                style={{
                  color: "red",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                This page is admin locked. Please contact us should you need
                something changed.
              </p>
            )}
            <SectionTitle {...{ value: "Room Types" }} />
            {this.isAdmin && (
              <>
                <br />
                {!this.state.isRTPanelOpened && (
                  <Button
                    aria-label="split"
                    variant="contained"
                    color="primary"
                    component="span"
                    id="add-room-type"
                    onClick={() => {
                      this.openEditRoomPanelByPlus(null);
                    }}
                    style={{
                      width: "50px",
                      height: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <AddIcon />
                  </Button>
                )}
              </>
            )}
            <br />
            <CSSTransition
              in={this.state.isRTPanelOpened}
              appear={true}
              exit={true}
              timeout={1000}
              classNames="fade"
              unmountOnExit
            >
              <EditRoomPanel
                key={this.state.roomData.id}
                hotel={this.hotel}
                roomType={this.state.roomData}
                allData={this.state.roomTypeData}
                onclose={this.closeRoomPanel}
                isNew={this.state.isRTPanelNew}
                onUpdate={this.updateRoomTypeinStore}
                unEditable={this.state.unEditable}
                counter={this.state.counter}
              ></EditRoomPanel>
            </CSSTransition>
            <Container>
              <MuiThemeProvider theme={rtTheme}>
                <GridDropZone
                  id="roomType"
                  boxesPerRow={4}
                  rowHeight={90}
                  disableDrag={!this.isAdmin}
                  disableDrop={!this.isAdmin}
                  // Remember to make it in round form
                  style={{
                    height: `${
                      Math.ceil(
                        ((this.state.roomTypeData.length / 4) * 100) / 100,
                      ) * 100
                    }px`,
                  }}
                >
                  {this.state.roomTypeData.map(
                    (
                      rt: { id: string; shortDescription: string },
                      index: number,
                    ) => (
                      <GridItem key={rt.id}>
                        <div>{this.getCardForRT(rt, index)}</div>
                      </GridItem>
                    ),
                  )}
                </GridDropZone>
              </MuiThemeProvider>
            </Container>

            <br />
            <Divider></Divider>
            <SectionTitle {...{ value: "Rate Plans" }} />
            {this.isAdmin && (
              <>
                <br />
                {!this.state.isRPPanelOpened && (
                  <Button
                    aria-label="split"
                    variant="contained"
                    color="primary"
                    id="add-rate-plan"
                    component="span"
                    onClick={() => {
                      this.openEditRPPanelByPlus(null);
                    }}
                    style={{
                      width: "50px",
                      height: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <AddIcon />
                  </Button>
                )}
              </>
            )}
            <br />
            <CSSTransition
              in={this.state.isRPPanelOpened}
              appear={true}
              exit={true}
              timeout={1000}
              classNames="fade"
              unmountOnExit
            >
              <EditRatePlanPanel
                key={this.state.rateplanData.id}
                hotel={this.hotel}
                ratePlan={this.state.rateplanData}
                onclose={this.closeRPPanel}
                isNew={this.state.isRPPanelNew}
                onUpdate={this.updateRPinStore}
                unEditableRP={this.state.unEditableRP}
                counter={this.state.counter}
              ></EditRatePlanPanel>
            </CSSTransition>
            <Container>
              <MuiThemeProvider theme={rtTheme}>
                <br />
                <div>
                  <Grid container spacing={3}>
                    <Grid item sm={3}>
                      <TextField
                        style={{ width: "100%", background: "white" }}
                        fullWidth
                        id="outlined-basic"
                        label="Search"
                        variant="outlined"
                        name="search"
                        onChange={(e) =>
                          this.handleChange(
                            e as SelectChangeEvent<string | string[]>,
                          )
                        }
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl variant="outlined">
                        <InputLabel id="demo-multiple-chip-label">
                          Room Type
                        </InputLabel>
                        <Select
                          style={{ width: "100%", background: "white" }}
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          label="Room Type"
                          name="roomType"
                          multiple
                          value={this.state.searchDropDown}
                          onChange={(e) => this.handleChange(e)}
                          renderValue={() => (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {(this.state.searchDropDown as string[])
                                .filter((value: string) => value !== "all")
                                .map((value: string) => {
                                  const chip = rtDetail.find(
                                    (item: { label: string; value: string }) =>
                                      item.value === value,
                                  );
                                  const label =
                                    typeof chip != "undefined"
                                      ? chip.label
                                      : "";

                                  if (label) {
                                    return (
                                      <Chip
                                        key={value}
                                        label={label}
                                        style={{ margin: "2px" }}
                                      />
                                    );
                                  }
                                })}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {rtDetail.map(
                            (rt: { label: string; value: string }) => (
                              <MenuItem key={rt.value} value={rt.value}>
                                <Checkbox
                                  checked={
                                    this.state.searchDropDown.indexOf(
                                      rt.value,
                                    ) > -1
                                  }
                                />

                                {rt.label}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl>
                        <InputLabel>Mode of sale</InputLabel>
                        <Select
                          value={this.state.searchModeOfSale}
                          style={{ width: "100%", background: "white" }}
                          fullWidth
                          label="Mode of sale"
                          variant="outlined"
                          name="searchModeOfSale"
                          onChange={(e) => this.handleChange(e)}
                        >
                          <MenuItem value="All">All</MenuItem>
                          <MenuItem value="OTA">OTA</MenuItem>
                          <MenuItem value="OWS">OWS</MenuItem>
                          <MenuItem value="GDS">GDS</MenuItem>
                          {this.hotel?.meta?.hasRewards && (
                            <MenuItem value="Rewards">Rewards</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl>
                        <InputLabel>Set Rate</InputLabel>
                        <Select
                          value={this.state.setRateFilter}
                          style={{ width: "100%", background: "white" }}
                          fullWidth
                          label="Set Rate"
                          variant="outlined"
                          name="setRateFilter"
                          onChange={(e) => this.handleChange(e)}
                        >
                          <MenuItem value="All">All</MenuItem>
                          <MenuItem value="set rate">Enabled</MenuItem>
                          <MenuItem value="without set rate">Disabled</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <br />
                <GridDropZone
                  id="ratePlan"
                  boxesPerRow={4}
                  rowHeight={90}
                  disableDrag={
                    !this.isAdmin &&
                    filteredData.length === this.state.ratePlanArray.length
                      ? false
                      : true
                  }
                  disableDrop={
                    !this.isAdmin &&
                    filteredData.length === this.state.ratePlanArray.length
                      ? false
                      : true
                  }
                  // Remember to make it in round form
                  style={{
                    height: `${
                      Math.ceil(((filteredData.length / 4) * 100) / 100) * 100
                    }px`,
                  }}
                >
                  {filteredData.map(
                    (
                      rp: {
                        id: string;
                        shortDescription: string;
                        applies?: string[];
                      },
                      index: number,
                    ) => (
                      <GridItem key={rp.id}>
                        <div>{this.getCardForRP(rp, index)}</div>
                      </GridItem>
                    ),
                  )}
                </GridDropZone>
              </MuiThemeProvider>
            </Container>

            <br />
          </div>
        </GridContextProvider>

        {/* Delete Dialog for Rp */}
        <Popup
          isPopupOpen={this.state.isDeleteRpDialogOpened}
          closeOnOutsideClick={true}
          title={`Delete ${this.state.rpToDelete.shortDescription}`}
          content={[
            "Please note:",
            "● This action is irreversible.",
            "● No changes will happen on the channel manager configuration.",
            "● This rate type will need to be deleted manually on the channel manager.",
            "Are you sure?",
          ]}
          action={() => {
            this.deleteRateplan(this.state.rpToDelete);
            this.closeDeleteDialog();
          }}
          actionName="Delete Rate Plan"
          cancelAction={this.closeDeleteDialog}
          cancelName="Cancel"
        />

        {/* Delete Dialog for Rt */}
        <Popup
          isPopupOpen={this.state.isDeleteRtDialogOpened}
          closeOnOutsideClick={true}
          title={`Delete ${this.state.rtToDelete.shortDescription}`}
          content={[
            "Please note:",
            "● This action is irreversible.",
            "● No changes will happen on the channel manager configuration.",
            "● This room type will need to be deleted manually on the channel manager.",
            "Are you sure?",
          ]}
          action={() => {
            this.deleteRoomType(this.state.rtToDelete);
            this.closeDeleteDialog();
          }}
          actionName="Delete Room Type"
          cancelAction={this.closeDeleteRtDialog}
          cancelName="Cancel"
        />
      </MuiThemeProvider>
    );
  }
}
const mapStateToProps = (state: {
  hotelData: { activeHotel: typeof emptyRp | typeof emptyRT };
}) => ({
  activeHotel: state.hotelData.activeHotel,
});
const mapDispatchToProps = (
  dispatch: (action: ReturnType<typeof setActiveHotel>) => void,
) => {
  return {
    setActiveHotel: (hotel: typeof emptyRp | typeof emptyRT) =>
      dispatch(setActiveHotel(hotel)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(RoomConfig));
