import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { tableTheme } from "../../../mui-theme";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WarningIcon from "@mui/icons-material/Warning";

export interface ICompSetUpdateprops {
  items: any;
  originalItems: any;
  hotelCompSet: any;
  parentCallback: (rp: any) => void;
}

interface State {
  data: [];
  originalData: any;
  changesCanceled: boolean;
  hotelCompSet: [];
  selectCompSet: [];
  tempArr: number[];
  reloadTable: [];
  switchList: [];
}

class CompSetsTable extends Component<ICompSetUpdateprops> {
  readonly state: State = {
    data: [],
    originalData: this.props.originalItems,
    changesCanceled: false,
    hotelCompSet: [],
    selectCompSet: [],
    tempArr: [],
    reloadTable: [],
    switchList: [],
  };

  constructor(props: any) {
    super(props);
    this.state.data = props.items;
    this.state.selectCompSet = [];
    this.state.tempArr = this.props.hotelCompSet;
    this.state.reloadTable = props.items;
  }

  handleOnClick = (event: any) => {
    let data = [];
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked === true) {
      const newTempArr = [...this.state.tempArr];
      if (newTempArr.indexOf(parseInt(value)) === -1) {
        newTempArr.push(parseInt(value));
      }
      data = this.state.data.map(function (val: {
        id: string;
        Active: boolean;
      }) {
        if (val.id === value) {
          val["Active"] = true;
        }
        return val;
      });
      this.setState(
        {
          data: data,
          tempArr: newTempArr,
        },
        () => {
          this.props.parentCallback(newTempArr);
        },
      );
    } else {
      if (value) {
        const newTempArr = [...this.state.tempArr];
        newTempArr.splice(newTempArr.indexOf(parseInt(value)), 1);
        data = this.state.data.map(function (val: {
          id: string;
          Active: boolean;
          name: string;
          updatedDate: string;
        }) {
          if (val.id === value) {
            val["Active"] = false;
          }
          return val;
        });
        this.setState(
          {
            data: data,
            tempArr: newTempArr,
          },
          () => {
            this.props.parentCallback(newTempArr);
          },
        );
      }
    }
  };

  formatDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split(/[-/]/);
    return `${day}-${month}-${year}`;
  };

  render() {
    const { data } = this.state;
    let currentDate = new Date().toLocaleDateString("en-GB");
    currentDate = this.formatDate(currentDate);
    return (
      <ThemeProvider theme={tableTheme}>
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: "600px",
            margin: "0 auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "15%" }} align="center">
                  Actions
                </TableCell>
                <TableCell sx={{ width: "55%" }}>Hotel Name</TableCell>
                <TableCell sx={{ width: "30%" }} align="center">
                  Updated Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((hotel: any, index: number) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <IconButton
                      onClick={() =>
                        this.handleOnClick({
                          target: { checked: !hotel.Active, value: hotel.id },
                        })
                      }
                      size="small"
                      sx={{
                        color: "#d32f2f",
                        "&:hover": {
                          backgroundColor: "rgba(211, 47, 47, 0.04)",
                        },
                      }}
                    >
                      {hotel.Active ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>{hotel.name}</TableCell>
                  <TableCell align="center">
                    {hotel.updatedDate}
                    {currentDate !== hotel.updatedDate && (
                      <Tooltip title="Data not updated today">
                        <WarningIcon
                          sx={{
                            color: "warning.main",
                            fontSize: "large",
                            marginLeft: 1,
                            verticalAlign: "middle",
                          }}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    );
  }
}

export default CompSetsTable;
